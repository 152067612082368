.fiche-article {
	padding-top: 4rem;
	padding-bottom: 8rem;
	gap: 4rem;
}
.panel-fiche-produit {
	/* margin: 0 auto; */
	background-color: #ffffff;
	border: 0px;
	border-radius: 5px;
	box-shadow: 0px 0px 6px #00000029;
	opacity: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem;
	width: 100%;
}

.panel-articles{
	width: 100%;
}

.panel-ficheProduitSupp {
	display: inline-block;
	/* margin-left: 14rem;
	margin-top: -2.5rem;
	margin-bottom: 4rem; */
}

.panel-fiche-produit-supp {
	/* margin: 0 auto; */
	width: 115px;
	/* height: 115px; */
	background-color: #ffffff;
	border: 0px;
	border-radius: 5px;
	box-shadow: 0px 0px 6px #00000029;
	opacity: 1;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.img-ficheProduit{
	width: 100%;
	height: auto;
	-o-object-fit: contain;
	object-fit: contain;
	display: block;
	cursor: pointer;
}

.panel-ficheProduit,
.panel-ficheProduitSupp {
	/* display: block; 
	width: 40%;
	margin-top: 1rem; */
	width: 50%;
	margin-top: 1rem;
}

.panel-ficheProduitSupp{
	width: 50%;
	margin-top: 1rem;
}

.info-vehicule-ficheProduit {
	background-color: var(--grisclair);
	padding: 3rem 4rem;
}

.nom-produit {
	margin-bottom: 2rem;
}

.prix-panier {
	margin-top: 3rem;
}

.info-produit{
	flex-direction: column;
	gap: 3rem;
	width: 100%;
}

.reference {
	margin-top: 2rem;
}

.tab-produit li {
	height: 4rem;
	display: flex;
	align-items: center;
	font-size: clamp(1.5rem, 1.5rem, 1.6rem);
}
.tab-produit li:nth-child(2n + 1) {
	background-color: var(--grisclair);
}

.tab-produit ul {
	list-style-type: none;
	width: 47rem;
}

.info li {
	color: var(--grisgadi);
	padding-left: 2rem;
}
.italic-items li {
	font-style: italic;
}

.info-rep li {
	padding-left: 4rem;
	padding-right: 4rem;
}

li .info {
	color: var(--grisgadi);
	padding-left: 2rem;
}
li .italic-items {
	font-style: italic;
}

li .info-rep {
	padding-left: 4rem;
	padding-right: 4rem;
}
.resultat-ref-equivalente {
	border-top: 0.125rem solid var(--grisprez);
	align-items: center;
	justify-content: space-between;
	gap: 5rem;
	padding: 2rem 0;
}
.resultat-ref-equivalente:first-of-type {
	border: none;
}
.nom-produit h2 {
	font-size: 2.5rem;
}
.nom-produit h3 {
	font-size: 2.25rem;
	font-family: var(--regular);
}
.nom-ref .ref {
	color: var(--bleutypofonce);
	margin-top: 1rem;
	font-family: var(--thin);
}

.marqueMaj .marque {
	color: var(--bleutypofonce);
	/* margin-top: 3rem; */
	font-family: var(--thin);
	text-transform: uppercase;
	/* margin-left: 5rem; */
}

/* .stock-commande {
	margin-top: 3rem;
	margin-left: 5rem;
} */
.prix * {
	color: var(--bleutypofonce);
	font-size: unset;
}
.prix span {
	font-size: 4.5rem;
}
.prix sup {
	font-size: 2.5rem;
	top: -0.9rem;
}
.prix span,
.prix sup {
	font-family: var(--bold);
}
.prix sup,
.masquer-listeArticle sup {
	text-transform: uppercase;
	position: relative;
}
.prix .ttc span {
	font-size: 2.375rem;
}
.prix .ttc sup {
	font-size: 1.5rem;
	top: -0.3rem;
}
.prix .ttc span,
.prix .ttc sup {
	font-family: var(--regular);
}
.prix-panier-listeArticle .prix .ttc sup {
	top: 0;
}
.prix .consigne sup {
	top: 0;
}
.prix .ht {
	margin-top: 1rem;
}
.prix .ht * {
	color: var(--bleutypo);
}
.prix .ttc * {
	color: var(--grisgadi);
}
.prix h3 {
	font-size: 2rem;
	margin-bottom: 1rem;
}
.masquer-listeArticle {
	margin-top: 1rem;
}
.masquer-listeArticle span {
	font-size: 2.625rem;
	font-family: var(--regular);
}
.masquer-listeArticle sup {
	font-size: 1.8rem;
	padding-left: 0.25rem;
	top: 0;
}
.nom-ref .nom {
	font-size: clamp(1.9rem, 2rem, 2.1rem);
	color: var(--bleutypofonce);
	font-family: var(--bold);
}

.prix .remise {
	color: var(--bleutypofonce);
}

.info-vehicule-ficheProduit i::before {
	font-size: clamp(5.5rem, 5.6rem, 5.7rem);
	padding-right: 4rem;
}

.info-vehicule-ficheProduit .marque-modele {
	margin: 0rem;
}
.marque-modele > h2 span{
	display: inline-block;
	text-transform: uppercase;
	margin-right: 0.5rem;
}

.masquer .icon-masquer-prix {
	font-size: clamp(2.5rem, 2.6rem, 2.7rem);
	width: 3.7rem;
	height: 3rem;
	margin-top: 1rem;
	cursor: pointer;
	margin-right: 2rem;
	margin-right: 10px;
	transition: transform 0.5s;
}
.input-centre {
	text-align: center;
}
/* Permet d'enlever les fleche dans l'input pour augmenter ou non la quantité */
.input-centre::-webkit-inner-spin-button,
.input-centre::-webkit-outer-spin-button,
.iuput-center::-moz-inner-spin-button,
.input-centre::-moz-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.panier .btn-ajoutPanier {
	margin-top: 2rem;
	background-color: var(--bleufond);
	color: var(--blanc);
	border-radius: 5rem;
	width: 27rem;
	font-family: var(--regular);
}

.panier .btn-ajoutPanier2 {
	margin-top: 0rem;
	background-color: var(--bleufond);
	color: var(--blanc);
	border-radius: 0rem 5rem 5rem 0rem;
	width: 9rem;
	margin-left: -0.2rem;
	font-family: var(--regular);
}
.prix .panier .btn-ajoutPanier2 i::before {
	font-size: 3rem;
	color: var(--blanc);
}

.panier .btn-ajoutPanier3 {
	margin-top: 0rem;
	background-color: var(--bleufond);
	color: var(--blanc);
	border-radius: 0rem 5rem 5rem 0rem;
	width: 9rem;
	margin-left: -0.2rem;
	font-family: var(--regular);
}

.prix .ht-masquer {
	color: var(--bleutypofonce);
	margin-top: 1.5rem;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease, transform 0.5s ease;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
	transform: translate(-10px);
}
.fade-enter-to,
.fade-leave {
	opacity: 1;
	transform: translate(0.6px);
}

.panel-consommableAssociee {
	display: flex;
	/* scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch; */
	justify-content: center;
	gap: 4rem;
}
.panel-consommableAssociee h3 {
	margin-top: 1.25rem;
}
.panel-consommableAssociee h3,
.panel-consommableAssociee h4 {
	margin-bottom: 1.25rem;
}
.panel-consommable {
	border: 0px;
	border-radius: 5px;
	box-shadow: 0px 0px 6px #00000029;
	background-color: var(--blanc);
	opacity: 1;
	padding: 4rem;
	width: 100%;
}
.resultat-ref-equivalente figure {
	border-radius: 0.3125rem;
	box-shadow: 0px 0px 3px #00000029;
	width: 10%;
}
.tab-infoVehic li {
	height: 4rem;
	display: flex;
	align-items: center;
	font-size: clamp(1.5rem, 1.5rem, 1.6rem);
}
.tab-infoVehic li:nth-child(2n + 1) {
	background-color: var(--grisclair);
}

.tab-infoVehic ul {
	list-style-type: none;
}
.info-vehic-first-plan {
	position: relative; /* ou absolute en fonction de tes besoins */
	z-index: 9999; /* Plus le nombre est grand, plus l'élément sera au premier plan */
}
.panel-consommable h4 {
	font-size: 2.1rem;
	padding-bottom: 1rem;
	font-family: var(--regular);
}

.prix-htConso .euro-bleu {
	color: var(--bleufond);
	font-size: 5rem;
	margin-top: 1rem;
	margin-left: 5rem;
}

.prix-htConso .centimes-bleu {
	color: var(--bleufond);
	font-size: 3rem;
	position: relative;
	top: -1.5rem;
}

.prix-htConso .ht {
	color: var(--bleutypofonce);
	position: relative;
	top: -1.5rem;
	font-size: 3rem;
}
.plus-info-prod {
	color: var(--bleugadisat);
	font-size: clamp(1.5rem, 1.5rem, 1.6rem);
	font-style: italic;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	cursor: pointer;
}

.ref {
	margin-bottom: 1rem;
}

.stock {
	margin-top: 1rem;
}

.stock-fiche-produit {
	padding-left: 40rem;
	margin-top: 2rem;
}
.plus-oe {
	border-radius: 1rem;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	cursor: pointer;

}
.plus-oe p {
	font-size: 1.5rem;
	margin-top: 0.5rem;
	text-align: center;
}
.plus-oe i {
	margin-right: 0.5rem;
}
.plus-oe p,
.plus-oe i {
	color:#000;
}

.bleu {
	color: var(--bleufond);
}
.jaune {
	color: var(--jauneE6AD00);
}
.vert {
	color: var(--vert00B100);
}
.selection-container p.stock-conso {
	margin-bottom: 1.25rem;
}
.selection-container p.stock-conso.bleu {
	color: var(--bleufond);
}
.selection-container p.stock-conso.jaune {
	color: var(--jauneE6AD00);
}
.selection-container p.stock-conso.vert {
	color: var(--vert00B100);
}
.panel-consommableAssociees{
	gap: 5rem;
}
.img-consommableAssociee{
	padding: 3rem 5rem 2rem 5rem;
}

/* Styles pour le bouton 360° */
.img-360{
	justify-content: center;
	margin-top: 1rem;
}
.button-360 {
	cursor: pointer;
	display: inline-block;
	background-color: transparent;
}

/* Styles pour le fond de la fenêtre pop-up */
.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Styles pour le contenu de la fenêtre pop-up */
.popup-content {
	position: relative;
	background: var(--blanc);
	padding: 1rem;
	border-radius: 5px;
	text-align: center;
	max-width: 500px;
	width: 100%;
}

/* Style pour le bouton de fermeture */
.close-btn-popup {
	background: none;
	border: none;
	font-size: 3rem;
	cursor: pointer;
	float: right;
	color: var(--bleugadisat);
}
.close-btn-popup:hover{
	color: var(--bleufond);
}

.popup-content img {
	max-width: 100%;
	height: auto;
}

.compatible{
	cursor: pointer;
}
.compatible i::before {
    padding-right: 0.5rem;
}


.pdfVisu {
	width: 100%;
	justify-content: end;
}
@media screen and (max-width: 576px) {
	.resultat-ref-equivalente {
		align-items: start;
		justify-content: unset;
		gap: 1rem;
	}
	.resultat-ref-equivalente figure {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: 4.1662rem;
		width: 25%;
	}
	.prix-panier-listeArticle .prix .ttc sup {
		top: 0.5rem;
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 899px) {

	.panel-consommableAssociees {
    overflow-x: auto; /* Active le défilement horizontal */
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; /* Fluide sur iOS */
    padding: 0.5rem;
    gap: 1rem; /* Espace entre les articles */
  }

	/* SI JE METS CECI SA NE FONCTIONNE PAS*/ 

	 .images-produit{
		display: flex;
		/* flex-direction: column; */
    overflow-x: auto; 
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding: 0.5rem;
    gap: 1rem;
  }

	.images-produit > article {
    flex: 0 0 200px; 
  } 

	
	.panel-consommableAssociees article{
		padding: 1rem;
	}

  /* Chaque article doit avoir une largeur minimale pour provoquer un débordement horizontal */
  .panel-consommableAssociees > article {
    flex: 0 0 250px; /* Largeur fixe pour provoquer le débordement */
    /* scroll-snap-align: start; */
  }

  .panel-fiche-produit figure,
  .panel-ficheProduitSupp figure {
    margin: 0;
    width: 100%; /* Assure que chaque figure prend la largeur de l'article */
		justify-content: center;
  }
	
	.img-consommableAssociee{
		padding: 3rem;
	}
	.images-container{
		width: 100%;
	}
}
@media screen and (min-width: 992px) {
}
@media screen and (max-width: 1024px) {
	/* Style pour l'overlay du pop-up */
	.popup-overlay {
		position: fixed !important; /* Utiliser position absolute */
		top: 0;
		left: 0;
		width: 100% !important; /* Couvrir tout l'écran */
		height: 100% !important;
		background: rgba(0, 0, 0, 0.7) !important; /* Fond semi-transparent */
		z-index: 99999 !important; /* Place très élevée pour apparaître devant tout */
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden !important;
		-webkit-overflow-scrolling: touch !important; /* Pour iOS */
	}
	/* Style pour le contenu de la pop-up */
	.popup-content {
		position: relative;
		background: white;
		padding: 20px;
		width: 90%; /* Ajuste la taille pour un écran mobile */
		max-width: 600px;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
		z-index: 100000 !important;
	}
	.button-360{
		width: 25%;
	}
	.panel-fiche-produit{
		padding: 1rem;
	}
	.fiche-article{
		flex-direction: column;
		padding-top: 2rem;
	}
	.img-Produit-ficheProduitSupp{
		width: 100%;
	}
	.nom-produit h2{
		font-size: 2rem;
	}
	.ref-stock .reference p{
		font-size: 1.5rem;
	}
	.produit{
		display: flex;
		justify-content: space-between;
	}
	.panel-ficheProduitSupp{
		margin: 0;
		margin: 0 auto;
    background-color: #ffffff;
    border: 0px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px #00000029;
    opacity: 1;
    display: flex;
    /* flex-direction: column; */
	}
	.panel-fiche-produit-supp {
    box-shadow: none;
		width: 100%;
	}
	.global-info-fiche-article {
    gap: 2rem;
		flex-direction: column;
	}
	.tab-produit ul {
    width: 17rem;
	}
	.tab-produit li{
		font-size: 1rem;
	}
	.panel-consommable{
		padding: 1rem;
		/* width: 70%; */
	}
	.panel-consommable h4 {
    font-size: 1.17rem;
	}
	.panel-consommable{
		padding: 1rem;
	}
	.panel-consommable h4 {
    font-size: 1.50rem;
	}
	

	/* .popup-content iframe img{
		width: 100%;
	}

	.popup-content iframe body{
		justify-content: center;
    align-items: center;
    display: flex;
	} */
}
@media screen and (min-width: 1200px) {
	.panel-articles{
		width: 30%;
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
