.list-items-mon-panier-save .row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.titre-livraison-sous-titre{
  padding: 3rem;
  margin-top: 3rem;
}
.titre-livraison {
  color: var(--bleugadisat);
  display: flex;
  font-size: 2.4rem;
  font-family: var(--bold);
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.titre-livraison-sous-titre p{
  color: var(--grisgadi);
}
.input-group-panier {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.input-group-panier i {
  height: 100%;
  width: 5rem;
}
.input-group-panier i::before {
  font-size: 3rem;
  height: 5rem;
  width: 100%;
  background-color: var(--bleufond);
  color: var(--blanc);
  border-radius: 5px 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
input.reference-commande-save {
  border: 1px solid var(--bleufond);
  border-left: none;
  border-radius: 0 5px 5px 0;
  cursor: not-allowed;
}
input.reference-commande::placeholder{
  color: var(--grisgadi);
  font-style: italic;
}
.address-options {
  display: flex;
  justify-content: space-between;
  padding: 0 3rem 2rem 3rem;
}
.address {
  display: flex;
  align-items: flex-start; /* Aligne le bouton radio avec le haut du texte */
  padding: 1rem;
  white-space: nowrap; /* Empêche le texte de se diviser en plusieurs lignes */
}
.address input[type="radio"] {
  accent-color: var(--bleugadisat);
  width: 2rem;
  display: block;
}
.address p {
  font-size: 1.4rem;
  color: var(--bleugadisat);
  margin: 0;
  line-height: 1.5;
}
.commande{
  justify-content: end;
}
.commande-btn {
  align-items: center;
  background-color: var(--bleufond);
  border: none;
  border-radius: 5rem;
  color: var(--blanc);
  cursor: pointer;
  display: flex;
  height: 5.5rem;
  justify-content: center;
  margin-top: 3rem;
  padding: 0 2rem;
  width: 30%;
  font-family: var(--regular);
}
.nom-adresse{
  padding-left: 1rem;
}

.commentaire-recap {
  width: 789px;
  height: 186px;
  display: flex;
  flex-direction: column;
  cursor: not-allowed;
}

.qte-panier-recap {
  background-color: var(--grisclair);
  color: var(--bleugadistat);
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  outline: none;
  width: 7.2rem;
  height: 4.3rem;
  font-family: var(--regular);
  pointer-events: none;
}

@media screen and (max-width: 390px) { 
  
}
@media screen and (max-width: 899px){
  .address input[type="radio"] {
    transform: scale(0.5); /* Taille ajustée du bouton radio */
  }
}
@media screen and (max-width: 1024px){
  .titre-mon-panier {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .mon-panier-icon {
    font-size: 3rem;
  }
  .col-item-produit{
    width: 100%;
  }
  .titre-livraison-sous-titre{
    margin-top: 1rem;
    padding: 2rem;
  }
  .titre-livraison-sous-titre .titre-livraison{
    font-size: 1.5rem;
  }
  .titre-livraison-sous-titre p{
    font-size: 1.5rem;
  }
  .address-options{
    flex-direction: column;
    padding: 0 2rem 2rem 2rem;
  }
  .list-items-mon-panier-save .row {
    gap: 1rem;
    margin-top: 2rem;
  }
  
}