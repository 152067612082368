/*MODAL*/

.custom-modal {
	display: none;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
}
/* .modal-window {
	visibility: hidden;
	margin: 0 auto;
	opacity: 0;
	position: relative;
	width: 87.5%;
	z-index: 4;
	transition: all ease-in-out 0.3s;
}
.modal-window.on {
	visibility: visible;
	opacity: 1;
	z-index: 11;
} */
.modal-window {
	display: none;
	margin: 0 auto;
	opacity: 0;
	position: relative;
	width: 87.5%;
	z-index: 4;
	transition: all ease-in-out 0.3s;
}
.modal-window.on {
	display: block;
	opacity: 1;
	z-index: 11;
}
.overlay-bleu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--bleugadisat); /* Utilise votre variable CSS */
	opacity: 0; /* Transparence */
	z-index: -1;
	transition: all ease-in-out 0.3s;
}
.overlay-bleu.on {
	opacity: 0.5; /* Transparence */
	z-index: 9;
}
.modal-content {
	background-color: #fff;
	margin: 0% auto;
	padding: 20px;
	border: 2px solid #f5f5f5;
	border-top: none;
	border-radius: 0 0 5px 5px;
	width: 100%;
}
.modal-content .col-50:first-of-type {
	padding-right: 1.5rem;
}
.modal-content .col-50:last-of-type {
	padding-left: 1.5rem;
}
.modal-header {
	margin-bottom: 1rem;
}

.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
	cursor: pointer;
	background-color: #fff;
	margin-top: -4rem;
}

.close-client{
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
	cursor: pointer;
	background-color: #fff;
	margin-top: -2rem;
}

.close:hover,
.close-client:hover,
.close:focus {
	color: var(--bleufond);
	/* color: var(--bleutypofonce); */
	text-decoration: none;
	cursor: pointer;
}
