[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "autoref" !important;
	speak-as: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-facture:before {
	content: "\e993";
}
.icon-panier-title:before {
	content: "\e992";
}
.icon-adresse:before {
	content: "\e98f";
}
.icon-email:before {
	content: "\e990";
}
.icon-telephone:before {
	content: "\e991";
}
.icon-client:before {
	content: "\e98d";
}
.icon-parametres:before {
	content: "\e98e";
}
.icon-abrasif:before {
	content: "\e900";
}
.icon-accessoires:before {
	content: "\e901";
}
.icon-achat-rapide:before {
	content: "\e902";
}
.icon-acoustique:before {
	content: "\e903";
}
.icon-adhesif:before {
	content: "\e904";
}
.icon-afficher-prix:before {
	content: "\e905";
}
.icon-articles-en-attente:before {
	content: "\e906";
}
.icon-auto:before {
	content: "\e907";
}
.icon-bas:before {
	content: "\e908";
}
.icon-baterie:before {
	content: "\e909";
}
.icon-boitier:before {
	content: "\e90a";
}
.icon-bougie:before {
	content: "\e90b";
}
.icon-cable:before {
	content: "\e90c";
}
.icon-carosserie:before {
	content: "\e90d";
}
.icon-carre:before {
	content: "\e90e";
}
.icon-changer-mine:before {
	content: "\e90f";
}
.icon-changer-PL:before {
	content: "\e910";
}
.icon-changer-selection:before {
	content: "\e911";
}
.icon-changer-vehicule:before {
	content: "\e912";
}
.icon-chaussure:before {
	content: "\e913";
}
.icon-cle:before {
	content: "\e914";
}
.icon-clim:before {
	content: "\e915";
}
.icon-commandes-en-cours:before {
	content: "\e916";
}
.icon-commandes-passees:before {
	content: "\e917";
}
.icon-commandes:before {
	content: "\e918";
}
.icon-compte:before {
	content: "\e919";
}
.icon-compteur:before {
	content: "\e91a";
}
.icon-courroie:before {
	content: "\e91b";
}
.icon-croix:before {
	content: "\e91c";
}
.icon-cube:before {
	content: "\e91d";
}
.icon-cutter:before {
	content: "\e91e";
}
.icon-distribution:before {
	content: "\e91f";
}
.icon-droite:before {
	content: "\e920";
}
.icon-dynamo:before {
	content: "\e921";
}
.icon-efficacite:before {
	content: "\e922";
}
.icon-electricite:before {
	content: "\e923";
}
.icon-electro:before {
	content: "\e924";
}
.icon-epi:before {
	content: "\e925";
}
.icon-equipementiers:before {
	content: "\e926";
}
.icon-essieu-arriere .path1:before {
	content: "\e927";
	color: rgb(4, 29, 91);
}
.icon-essieu-arriere .path2:before {
	content: "\e928";
	margin-left: -1.12890625em;
	color: rgb(4, 29, 91);
}
.icon-essieu-arriere .path3:before {
	content: "\e929";
	margin-left: -1.12890625em;
	color: rgb(4, 29, 91);
}
.icon-essieu-arriere .path4:before {
	content: "\e92a";
	margin-left: -1.12890625em;
	color: rgb(33, 117, 255);
}
.icon-essieu-arriere .path5:before {
	content: "\e92b";
	margin-left: -1.12890625em;
	color: rgb(33, 117, 255);
}
.icon-essieu-avant .path1:before {
	content: "\e92c";
	color: rgb(4, 29, 91);
}
.icon-essieu-avant .path2:before {
	content: "\e92d";
	margin-left: -1.12890625em;
	color: rgb(4, 29, 91);
}
.icon-essieu-avant .path3:before {
	content: "\e92e";
	margin-left: -1.12890625em;
	color: rgb(4, 29, 91);
}
.icon-essieu-avant .path4:before {
	content: "\e92f";
	margin-left: -1.12890625em;
	color: rgb(33, 117, 255);
}
.icon-essieu-avant .path5:before {
	content: "\e930";
	margin-left: -1.12890625em;
	color: rgb(33, 117, 255);
}
.icon-ete:before {
	content: "\e931";
}
.icon-etoile:before {
	content: "\e932";
}
.icon-filtre:before {
	content: "\e933";
}
.icon-flacon:before {
	content: "\e934";
}
.icon-fleche-down:before {
	content: "\e935";
}
.icon-flexible:before {
	content: "\e936";
}
.icon-frein:before {
	content: "\e937";
}
.icon-gammes:before {
	content: "\e938";
}
.icon-gant:before {
	content: "\e939";
}
.icon-garage:before {
	content: "\e93a";
}
.icon-gauche:before {
	content: "\e93b";
}
.icon-gel:before {
	content: "\e93c";
}
.icon-GPS:before {
	content: "\e93d";
}
.icon-haut:before {
	content: "\e93e";
}
.icon-hiver:before {
	content: "\e93f";
}
.icon-huile:before {
	content: "\e940";
}
.icon-huiles:before {
	content: "\e941";
}
.icon-immat-pl:before {
	content: "\e942";
}
.icon-immat:before {
	content: "\e943";
}
.icon-Info:before {
	content: "\e944";
}
.icon-joint:before {
	content: "\e945";
}
.icon-lecture:before {
	content: "\e946";
}
.icon-left:before {
	content: "\e947";
}
.icon-livraison:before {
	content: "\e948";
}
.icon-losange:before {
	content: "\e949";
}
.icon-lunette:before {
	content: "\e94a";
}
.icon-marteau:before {
	content: "\e94b";
}
.icon-masquer-prix:before {
	content: "\e94c";
}
.icon-mecha:before {
	content: "\e94d";
}
.icon-mes-Listes:before {
	content: "\e94e";
}
.icon-mesure:before {
	content: "\e94f";
}
.icon-modifier-pneu:before {
	content: "\e950";
}
.icon-modifier:before {
	content: "\e951";
}
.icon-moteur:before {
	content: "\e952";
}
.icon-multi:before {
	content: "\e953";
}
.icon-organiser:before {
	content: "\e954";
}
.icon-origine:before {
	content: "\e955";
}
.icon-outillage:before {
	content: "\e956";
}
.icon-outils:before {
	content: "\e957";
}
.icon-panier:before {
	content: "\e958";
}
.icon-parc:before {
	content: "\e959";
}
.icon-pare:before {
	content: "\e95a";
}
.icon-peinture:before {
	content: "\e95b";
}
.icon-penta:before {
	content: "\e95c";
}
.icon-piece-auto:before {
	content: "\e95d";
}
.icon-piecesauto:before {
	content: "\e95e";
}
.icon-pied:before {
	content: "\e95f";
}
.icon-pile:before {
	content: "\e960";
}
.icon-pl:before {
	content: "\e961";
}
.icon-plaque:before {
	content: "\e962";
}
.icon-pneu:before {
	content: "\e963";
}
.icon-pneumatiques:before {
	content: "\e964";
}
.icon-poids:before {
	content: "\e965";
}
.icon-pot:before {
	content: "\e966";
}
.icon-prix:before {
	content: "\e967";
}
.icon-pyramide:before {
	content: "\e968";
}
.icon-quantie:before {
	content: "\e969";
}
.icon-radiateur:before {
	content: "\e96a";
}
.icon-recherche:before {
	content: "\e96b";
}
.icon-rechercher:before {
	content: "\e96c";
}
.icon-retour-en-cours:before {
	content: "\e96d";
}
.icon-retour:before {
	content: "\e96e";
}
.icon-retours-passes:before {
	content: "\e96f";
}
.icon-right:before {
	content: "\e970";
}
.icon-rond:before {
	content: "\e971";
}
.icon-rouleau:before {
	content: "\e972";
}
.icon-roulement:before {
	content: "\e973";
}
.icon-SAV:before {
	content: "\e974";
}
.icon-secu:before {
	content: "\e975";
}
.icon-securise:before {
	content: "\e976";
}
.icon-shock:before {
	content: "\e977";
}
.icon-siege:before {
	content: "\e978";
}
.icon-sol-mouille:before {
	content: "\e979";
}
.icon-spray:before {
	content: "\e97a";
}
.icon-stock:before {
	content: "\e97b";
}
.icon-supprimer:before {
	content: "\e97c";
}
.icon-torche:before {
	content: "\e97d";
}
.icon-tourne:before {
	content: "\e97e";
}
.icon-toute-saison:before {
	content: "\e97f";
}
.icon-transmission:before {
	content: "\e980";
}
.icon-triangle:before {
	content: "\e981";
}
.icon-tube:before {
	content: "\e982";
}
.icon-update:before {
	content: "\e983";
}
.icon-valide-plein:before {
	content: "\e984";
}
.icon-valide:before {
	content: "\e985";
}
.icon-vehicule-info:before {
	content: "\e986";
}
.icon-vehicule-selectionne:before {
	content: "\e987";
}
.icon-visserie:before {
	content: "\e988";
}
.icon-vitesse:before {
	content: "\e989";
}
.icon-volant:before {
	content: "\e98a";
}
.icon-VUL:before {
	content: "\e98b";
}
.icon-wipe:before {
	content: "\e98c";
}
