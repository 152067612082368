.dropdown-modele-portail.dropdown-select-portail {
  background-color: var(--blanc);
  color: var(--grisgadi);
  border: none;
  height: 4.5rem;
  margin: 0;
  margin-top: 2rem;
  padding: 0 2rem;
  padding-right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
}
.dropdown-modele-portail.dropdown-select-portail .icon {
  background-color: var(--bleufond);
  border-radius: 0 0.5rem 0.5rem 0;
  color: var(--blanc);
  display: flex;
  height: 100%;
  padding: 0 1.5rem;
}
.dropdown-modele-portail.dropdown-select-portail .icon::before {
  color: var(--blanc);
  align-items: center;
}
.dropdown-modele-portail.dropdown-select-portail,
.dropdown-modele-portail.dropdown-select-portail .icon::before {
  font-size: 1.4rem;
}
.dropdown-list-modele-portail.dropdown-menu-generique-portail.show {
  margin: unset;
  width: 100%;
}
.recherche-modele {
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
