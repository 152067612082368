.en-cours-dev {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--grisclair);
}

.content {
  text-align: center;
  background-color: var(--blanc);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1.dev-en-cours{
  font-size: 2.5em;
  color: var(--bleufond);
}

p.patience-dev {
  font-size: 1.2em;
  color: var(--bleugris);
}

@media screen and (max-width: 1024px){
  .en-cours-dev{
    height: 100%;
  }
  h1.dev-en-cours{
    font-size: 1.8rem;
  }
  p.patience-dev{
    font-size: 1.5rem;
  }
}