.container-autre-recherche {
	position: relative;
}
.modif-vehic {
	align-items: center;
	background-color: transparent;
	border: 0.2rem solid var(--grisgadi);
	border-radius: 0.5rem;
	cursor: pointer;
	display: flex;
	height: 6rem;
	justify-content: space-between;
	padding: 0 calc(4rem - 0.2rem);
	width: 100%;
}
.content-panel-modif {
	padding: 3rem 0;
	position: relative;
}
.content-panel-modif:first-of-type {
	padding-top: 0;
}
.content-panel-modif::after {
	content: "";
	background-color: var(--grisgadi);
	bottom: 0;
	height: 0.5rem;
	position: absolute;
	left: -3rem;
	right: -3rem;
}
.content-panel-modif:last-of-type::after {
	display: none;
}
.content-panel-modif .plaque-immat.modif-immat {
	height: unset;
	width: 100%;
}
.content-panel-modif button {
	background-color: var(--bleufond);
	color: var(--blanc);
	font-size: clamp(2rem, 1vw, 2.4rem);
	height: 6rem;
	margin: 0;
	margin-top: 2rem;
	width: 100%;
}
.content-panel-modif .input-control-typeMine {
	margin: 0;
	margin-top: 2rem;
	height: 6rem;
	width: 100%;
}
.header-modif-vehic {
	display: flex;
	align-items: center;
}
.header-modif-vehic span {
	color: var(--grisgadi);
	display: flex;
	padding-left: 4rem;
	font-size: 2.4rem;
	font-weight: bold;
	height: 2rem;
	text-transform: uppercase;
}
.modif-immat .input-group-modif {
	margin: 0;
}
.modif-immat .plaque-immat {
	width: 100%;
}
.icon-changer-vehicule {
	display: inline-block;
}
.icon-changer-vehicule:before,
.icon-fleche-down:before {
	display: inherit;
	font-size: clamp(3.5rem, 4rem, 4.5rem);
	color: var(--grisgadi);
}
.icon-fleche-down:before {
	font-size: clamp(2rem, 2.5rem, 3rem);
}
.modif-vehic:hover {
	background-color: var(--grisgadi);
	border-color: var(--grisgadi);
	transition: all 0.3s ease, color 0.3s ease-in-out;
}
.modif-vehic:hover .icon-changer-vehicule:before,
.modif-vehic:hover .icon-fleche-down:before,
.modif-vehic:hover span,
.modif-vehic.active .icon-changer-vehicule:before,
.modif-vehic.active .icon-fleche-down:before,
.modif-vehic.active span {
	transition: all 0.3s ease, color 0.3s ease-in-out;
	color: var(--blanc);
}
.num-immat-modif{
	color: var(--bleugadisat);
	text-transform: uppercase;
}
.modif-vehic.active {
	background-color: var(--grisgadi);
	color: var(--blanc);
	border-color: var(--grisgadi);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.modif-vehic.active .icon-fleche-down:before {
	transform: rotate(180deg); /* Rotation de 180 degrés */
}
.content-panel-model-vehic ul {
	margin: 0;
}
.dropdown-modele.dropdown-select {
	background-color: var(--blanc);
	border: none;
	font-size: clamp(1.6rem, 1.8rem, 2rem);
	margin: 0;
	margin-top: 2rem;
	padding: 0 2rem;
	padding-right: 0;
	width: 100%;
}
.dropdown-modele.dropdown-select .icon {
	background-color: var(--bleufond);
	border-radius: 0 0.5rem 0.5rem 0;
	color: var(--blanc);
	display: flex;
	height: 100%;
	padding: 0 1.5rem;
}
.dropdown-modele.dropdown-select .icon::before {
	color: var(--blanc);
	font-size: clamp(1.6rem, 1.8rem, 2rem);
	line-height: 3.8rem;
}
.dropdown-list-modele.dropdown-menu-generique.show {
	margin: unset;
	width: 100%;
}

@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 899px) {
	.container-autre-recherche{
		display: none;
	}
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
