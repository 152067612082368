.btn-up {
	background-color: var(--overlaybleu);
	color: var(--blanc);
	position: fixed;
	width: 50px;
	height: 50px;
	right: 50px;
	bottom: 50px;
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 0.3s ease;
}
.btn-up:hover {
	cursor: pointer;
}
.btn-up.show {
	opacity: 1;
}
