.container-skills {
	background-color: var(--bleufond);
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	justify-content: center;
	padding: 2.5rem 0;
}
.container-skills figure {
	height: 9rem;
}
.container-skills figure img {
	object-fit: contain;
}
.container-skills li {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
}
.container-skills li:nth-child(1n + 3),
.container-skills li:last-of-type {
	margin-top: 1.5rem;
}

.container-skills p {
	font-size: 0.8rem;
	line-height: 1.25;
	font-weight: 600;
	letter-spacing: 0.2rem;
	text-align: center;
	color: var(--blanc);
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 899px) {
	.container-skills{
		gap: 0.5rem;
	}
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
	.container-skills ul {
		padding: 0;
		min-height: 25.5rem;
	}
	.container-skills p {
		font-size: 1rem;
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
