/* .results-search-ref-produits {
  display: none;
} */
.results-search-ref-produits .info-vehicule-produit {
	background-color: var(--grisprez);
	padding: 4rem;
	width: unset;
	border-radius: 0 5px 0px 0px;
}
.results-search-ref-produits .global-info {
	background-color: var(--blanc);
	padding: 3rem;
	gap: 4rem;
}
.results-search-ref-produits .panel-liste-article {
	width: 15%;
}
.results-search-ref-produits .panel-info-article {
	display: flex;
	gap: 8rem;
	width: 85%;
}
.results-search-ref-produits .prix-panier-listeArticle,
.results-search-ref-produits .nom-produit,
.results-search-ref-produits .stock-liste-article {
	display: flex;
	margin: unset;
	padding: unset;
	justify-content: space-between;
	align-items: end;
}
.results-search-ref-produits .info-tableau-ref-stock {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 50%;
}
.results-search-ref-produits .info-tableau-ref-stock h3 {
	font-size: 2rem;
}
.results-search-ref-produits .stock-liste-article {
	margin: unset;
}
.results-search-ref-produits .nom-produit {
	justify-content: space-between;
}
.results-search-ref-produits li .info {
	padding: 0;
}
.results-search-ref-produits .consigne span {
	font-size: 1.5rem;
}
.results-search-ref-produits .prix h4 span,
.results-search-ref-produits .prix h4 sup {
	font-family: var(--bold);
}
.results-search-ref-produits .prix h4.ttc {
	top: unset;
}
.results-search-ref-produits .prix h4.ttc span,
.results-search-ref-produits .prix h4.ttc sup {
	font-family: var(--regular);
}
.results-search-ref-produits .prix h4.ttc sup {
	font-size: 1.5rem;
}
.results-search-ref-produits .panier-listeArticle {
	width: 35%;
}
.results-search-ref-produits .panier-listeArticle .quantite-listeArticle,
.results-search-ref-produits .btn-ajoutPanier-listeArticle {
	width: unset;
}
.results-search-ref-produits .ref {
	margin: unset;
	margin-top: 1.5rem;
}
.results-search-ref-produits .btn-ajoutPanier-listeArticle span {
	direction: ltr;
	display: block;
	text-indent: -999em;
}
.results-search-ref-produits .btn-ajoutPanier-listeArticle {
	width: 100%;
}
.results-search-ref-produits .btn-ajoutPanier-listeArticle i {
	margin: 0;
}
.results-search-ref-produits .prix {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.results-search-ref-produits .prix .ttc {
	font-size: 2.5rem;
}
.results-search-ref-produits .masquer-listeArticle h4,
.results-search-ref-produits .masquer-listeArticle i {
	align-items: center;
	margin: unset;
}
.results-search-ref-produits .masquer-listeArticle h4 {
	margin-left: 0.5rem;
}
.remise {
	margin-top: 1.5rem;
}
.selection-produits-gris-ref {
	background-color: var(--grisprez);
	padding: 3rem;
	margin-top: -3rem;
	border-radius: 0px 0px 5px 5px;
}
.results-search-ref-produits .selection-ref-blanc {
	padding: 3rem;
}
.results-search-ref-produits .resultat-ref-equivalente .selection-container h2 {
	font-size: 1.5rem;
}
/* .results-search-ref-produits .resultat-ref-equivalente {
  justify-content: space-between;6
  align-items: center;
} */
.descript-other-ref{
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}
.descript-other-ref .panier{
	width: 16%;
}
.descript-other-ref h4.marque{
	text-transform: uppercase;
}
.descript-other-ref .prix span,
.descript-other-ref .prix sup{
	color: var(--bleutypo);
}
.descript-other-ref .prix b{
	color: var(--bleutypofonce);
}
@media screen and (max-width: 576px) {
	.results-search-ref-produits .info-vehicule-produit {
		border-radius: 0 0 0.3125rem 0.3125rem;
	}
	.container.results-search-ref-produits {
		border-radius: 0.5rem;
		padding: 1rem;
	}
	.results-search-ref-produits .nom-produit {
		flex-direction: column;
	}
	.results-search-ref-produits .global-info,
	.results-search-ref-produits .selection-ref-blanc {
		padding: 2rem;
		gap: 1rem;
	}
	.results-search-ref-produits .panel-info-article {
		flex-direction: column;
		gap: unset;
	}
	.results-search-ref-produits .nom-produit {
		align-items: start;
		justify-content: unset;
	}
	.results-search-ref-produits .info-tableau-ref-stock,
	.panel-liste-article {
		margin: 0;
		width: 100%;
	}
	.results-search-ref-produits .panel-liste-article {
		height: 10rem;
		min-width: 34%;
	}
	.results-search-ref-produits .info-vehicule-produit,
	.selection-produits-gris-ref {
		padding: 1rem;
	}
	.resultat-ref-equivalente {
		padding: 1rem 0;
	}
	.selection-produits-gris-ref {
		margin-top: -1rem;
	}
	.selection-ref-blanc {
		padding: 2rem;
	}
	.results-search-ref-produits .nom-produit h2 {
		font-size: 1.25rem;
	}
	.results-search-ref-produits .nom-produit h3 {
		margin: 0.5rem 0;
	}
	.results-search-ref-produits .nom-produit h3 {
		font-size: 1.25rem;
	}
	.results-search-ref-produits .consigne span {
		font-size: 1rem;
	}
	.results-search-ref-produits p {
		font-size: 1rem;
	}
	.results-search-ref-produits .ref {
		margin-top: 0.5rem;
	}
	.results-search-ref-produits .criteria-list {
		display: none;
	}
	.results-search-ref-produits .prix-panier-listeArticle {
		align-items: unset;
		gap: 1rem;
		justify-content: unset;
	}
	.descript-other-ref{
		position: relative;
		flex-direction: column;
		width: 100%;
	}
	.resultat-ref-equivalente .btn-ajoutPanier-listeArticle{
		display: inline-block;
		position: absolute;
		margin: 0;
		bottom: 0;
		right: 0;
		width: 25%;
	}
	.prix span {
		font-size: 2.3rem;
		padding: 0;
	}
	.prix sup {
		font-size: 1.5rem;
		top: 0rem;
	}
	.prix .ht {
		color: var(--bleutypo);
		top: 0;
	}
	.prix h4.ttc span {
		font-size: 2rem;
	}
	.prix h4.ttc sup {
		font-size: 1.25rem;
	}
	.prix .consigne sup {
		font-size: 0.8rem;
	}
	.prix .masquer-listeArticle span {
		font-size: 1.5rem;
	}
	.prix .masquer-listeArticle sup {
		font-size: 0.8rem;
	}
	.descript-other-ref h3,
	.descript-other-ref h4{
		margin-bottom: .5rem;
	 }
	.quantite-listeArticle {
		position: relative;
	}
	.quantite-listeArticle ul {
		list-style: none;
	}
	.quantite-listeArticle ul li {
		line-height: 1.2rem;
	}
	.qte-choix {
		align-items: center;
		border: 0.0625rem solid var(--grisgadi);
		border-radius: 3.125rem;
		display: flex;
		height: 3.125rem;
		justify-content: space-between;
		padding: 0 1rem;
		position: relative;
		width: 100%;
	}
	.qte-choix.active {
		border-bottom: 0rem;
		border-radius: 1.8rem 1.8rem 0 0;
	}
	.qte-choix i {
		color: var(--bleugadisat);
		font-size: 1.1rem;
	}
	.qte-choix i::before {
		color: inherit;
		font-size: inherit;
	}
	.qte-choix i::before {
		transition: all ease-in-out 0.3s;
	}
	.qte-choix.active i::before {
		transform: rotate(180deg);
	}
	.list-nbre-qte {
		background-color: var(--blanc);
		border: 0.0625rem solid var(--grisgadi);
		border-top: 0rem;
		border-radius: 0 0 1.8rem 1.8rem;
		padding: 0.3125rem;
		padding-left: 1rem;
		position: absolute;
		width: 100%;
	}
	.list-nbre-qte li {
		padding: 1rem 0;
	}
	.descript-other-ref h3,
	.descript-other-ref h4 {
		font-size: 1.1669rem;
	}
	.descript-other-ref h4{
		font-family: var(--thin);
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (max-width: 1024px) {
	.panel-liste-article {
		width: 50%;
	}
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
