
.item-mon-panier-valid {
  box-shadow: var(--shadow);
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 5px;
}
.message-commande{
  padding: 3rem 0 1rem 0;
}
.message-commande::before{
  content: "";
  background-color: var(--bleufond);
  height: 4%;
  width: 0.5rem;
  position: absolute;
}
.message-commande p{
  font-size: 1.6rem;
  color: var(--bleufond);
  font-family: var(--bold);
  padding: 0 1.5rem;
}
h4.texte-reference-commande{
  margin-top: 1rem;
  color: var(--bleufond);
  font-family: var(--thin);
}
span.ref-commande{
  color: var(--bleufond);
  font-family: var(--bold);
}
.btn-fin-commande{
  flex-direction: column;
  width: 30%;
}
.btn-fin-commande i::before{
  padding-right: 1rem;
  font-size: 1.5rem;
  align-items: center;
  display: flex;
}
.recommande-btn {
  font-size: 1.5rem;
  align-items: center;
  background-color: var(--bleufond);
  border: none;
  border-radius: 5rem;
  color: var(--blanc);
  cursor: pointer;
  display: flex;
  height: 5.5rem;
  justify-content: center;
  padding: 0 2rem;
  width: 100%;
  font-family: var(--regular);
}
.imprimer-btn{
  font-size: 1.5rem;
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--bleugadisat);
  border-radius: 5rem;
  color: var(--bleugadisat);
  cursor: pointer;
  display: flex;
  height: 5.5rem;
  justify-content: center;
  margin-top: 1rem;
  padding: 0 2rem;
  width: 100%;
  font-family: var(--regular);
}
@media screen and (max-width: 1024px){
  .btn-fin-commande{
    width: 100%;
    margin-top: 2rem;
  }
  .message-commande {
    padding: 1rem 0 1rem 0;
  }
  .message-commande::before{
    content: "";
    background-color: var(--bleufond);
    height: 9%;
    width: 0.5rem;
    position: absolute;
  }
}