.breadcrumb {
	margin: 0 auto;
	margin-top: 2rem;
	display: flex;
	align-items: center;
}
.breadcrumb span {
	list-style: none;
	display: flex;
}
.breadcrumb span .breadcrumb-link {
	align-items: center;
	background: var(--grisclair);
	display: flex;
	color: var(--bleugadisat);
	font-size: clamp(1.6rem 1.8rem 2rem);
	height: 6rem;
	padding: 0 2rem 0 5rem;
	position: relative;
	margin: 0 auto;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.breadcrumb span .breadcrumb-link:after {
	border-top: 30px solid transparent;
	border-bottom: 30px solid transparent;
	border-left: 30px solid var(--grisarrow);
	content: "";
	position: absolute;
	right: -3rem;
	top: 0;
	z-index: 1;
}
.breadcrumb span .breadcrumb-link:before {
	content: "";
	border-top: 30px solid transparent;
	border-bottom: 30px solid transparent;
	border-left: 30px solid var(--blanc);
	position: absolute;
	left: 0;
	top: 0;
}

.breadcrumb span:first-child .breadcrumb-link:before {
	display: none;
}

.breadcrumb span:last-child .breadcrumb-link {
	padding-right: 4rem;
	cursor: pointer;
}

.breadcrumb span:last-child .breadcrumb-link:after {
	display: none;
}

.breadcrumb span .breadcrumb-link:hover {
	background: var(--grisprez);
	color: var(--bleugadisat);
}

.breadcrumb span .breadcrumb-link:hover:after {
	border-left-color: var(--grisprez);
	color: var(--blanc);
}

span .breadcrumb-separator {
  margin: 0 auto;
  align-items: center;
}

span .breadcrumb-current{
	padding-left: 3rem;
}
.selection-produits-gris .breadcrumb {
	margin-top: 0;
	padding-top: 2rem;
}
@media screen and (max-width: 576px) {
	.breadcrumb {
		margin-left: 0;
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
