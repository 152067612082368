.container-parc {
	padding: 0;
}
.panel-recherche-vehic {
	background-color: var(--blanc);
	box-shadow: var(--shadow);
	padding: 2rem 4rem 4rem 4rem;
}
.titre-recherche-vehic,
.titre-mon-parc {
	align-items: center;
	color: var(--bleugadisat);
	display: flex;
	font-size: 3rem;
	font-family: var(--regular);
}
.titre-recherche-vehic span,
.titre-mon-parc span {
	display: block;
	height: 3rem;
	font-size: 4rem;
}
.titre-mon-parc i::before,
.titre-recherche-vehic i::before {
	color: var(--bleugadisat);
	font-size: 5rem;
	margin-right: 4rem;
}

.label-parc {
	color: var(--bleugadisat);
	font-size: 2rem;
}
.recherche-input label {
	padding-bottom: 2rem;
}
.recherche-vehic {
	justify-content: space-between;
	padding-top: 4rem;
	align-items: end;
}
.input-recherche-vehic {
	border: 1px solid var(--grisprez); /* Bordure verte de 2px */
	border-radius: 5px; /* Coins arrondis */
	padding: 0 1.8rem; /* Espace interne pour un meilleur rendu */
	font-size: 1.6rem; /* Taille du texte */
}
.input-recherche-vehic::placeholder {
	color: var(--grisprez); /* Change la couleur du placeholder en gris */
	opacity: 1; /* Assure que la couleur est pleinement visible (sans transparence) */
}
/* Pour certains navigateurs comme Firefox */
.input-recherche-vehic:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--grisprez);
}
.input-recherche-vehic::-ms-input-placeholder {
	/* Microsoft Edge */
	color: var(--grisprez);
}

.bouton-rechercher-vehic {
	justify-content: flex-end;
	padding-top: 2rem;
}

.btn-recherche-vehic {
	margin-left: 1rem;
}

.btn-recherche-vehic,
.btn-reinitialisation-vehic {
	align-items: center;
	background-color: var(--bleugadisat);
	border: none;
	border-radius: 0.5rem;
	color: var(--blanc);
	cursor: pointer;
	display: flex;
	justify-content: center;
}

.btn-recherche-vehic i.icon,
.btn-reinitialisation-vehic i.icon {
	margin: 0.5rem;
}
.btn-recherche-vehic i::before {
	margin: 1.5rem;
}
.panel-mon-parc {
	background-color: var(--blanc);
	box-shadow: var(--shadow);
	padding: 4rem;
	margin-top: 6rem;
}
.panel-tri-mon-parc {
	border-radius: 0px;
	height: 6rem;
	background-color: var(--grisclair);
	margin-top: 4rem;
	cursor: pointer;
}
.label-tri,
.label-mon-parc {
	width: 50%;
	display: flex;
	align-items: center;
}
.label-tri i {
	margin-left: 2rem;
}
.label-tri i::before {
	font-size: 1.5rem;
	color: var(--grisgadi);
}

.info-mon-parc {
	list-style-type: none;
	padding: 0;
	padding-left: 4rem;
	width: 100%;
	align-items: center;
}
.info-mon-parc li {
	display: flex;
	width: 60%;
}
/* .info-mon-parc li + li {
  width: 40%;
} */

.liste-mon-parc {
	padding: 3rem 0 3rem 4rem;
	border-bottom: 0.2rem solid var(--grisgadi);
}

.immatriculation {
	font-family: var(--bold);
}

.panel-page {
	background-color: var(--blanc);
	border: 1px solid var(--grisprez);
	/* padding: 3rem; */
	margin: 6rem auto; /* Centre l'élément */
	border-radius: 5px;
	width: 30%;
	padding: 1rem 0;
	justify-content: center;
	align-items: center;
	display: flex;
}

.item-page {
	list-style: none;
	display: flex;
}

.item-page span,
.item-page i {
	margin: 0 1.5rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: var(--bleugadisat);
}

.list-page {
	list-style-type: none;
	align-items: center;
}

span.text-item-nav {
	left: -9999px;
	position: absolute;
}

.page-current {
	border-radius: 10px;
	background-color: var(--bleutypofonce);
	color: var(--blanc);
	height: 4rem;
	width: 4rem;
}

.panel-page .list-page li:first-child {
	margin: 0 4rem;
	/* z-index: -999; */
}

.panel-page .list-page li:last-child {
	margin: 0 4rem;
	/* z-index: -999; */
}

.panel-page .list-page li:nth-child(2) {
	padding-left: 0;
	margin-left: -1.5rem;
}

/* Cible l'avant-dernier élément et enlève 1.5rem à droite */
.panel-page .list-page li:nth-last-child(2) {
	padding-right: 0;
	margin-right: -1.5rem;
}

.immatriculation a {
	color: var(--bleugadisat);
}

@media screen and (max-width: 899px) {
	.recherche-filtre-parc {
		padding: 2rem 2rem 0 2rem;
	}
	.icon-parametres::before {
		font-size: 3rem;
		height: 2.8rem;
		width: 1.1rem;
		cursor: pointer;
		color: var(--blanc);
	}
	.input-filtre-parc {
		border: 1px solid var(--grisprez);
	}
	.input-filtre-parc::placeholder {
		color: var(--grisgadi);
		font-style: italic;
	}
	.btn-filtre {
		margin-left: 1rem;
		background-color: var(--grisgadi);
	}
	.panel-mon-parc {
		margin-top: 0rem;
		padding: 2rem;
		box-shadow: none;
	}
	.titre-mon-parc {
		margin-bottom: 2rem;
	}
	.titre-mon-parc i::before {
		font-size: 3.6931rem;
		margin-right: 1.1444rem;
	}
	.titre-mon-parc span {
		font-size: 2rem;
		display: flex;
		align-items: center;
		font-family: var(--bold);
	}
	.info-mon-parc li:nth-child(odd) {
		background-color: var(--grisclair);
	}
	.info-mon-parc {
		flex-direction: column; /* Aligne les éléments en colonne */
		padding-left: 0; /* Pour supprimer le padding gauche */
	}

	.info-mon-parc li {
		width: 100%; /* Prend toute la largeur sur mobile */
		padding: 1rem;
	}

	/* Pour que les deux premiers éléments soient sur la même ligne */
	.liste-mon-parc {
		display: flex;
		flex-wrap: wrap; /* Permet de passer à la ligne suivante si nécessaire */
		justify-content: space-between; /* Espace entre les éléments */
	}

	.info-mon-parc li:nth-child(-n + 2) {
		width: 100%; /* Les deux premiers éléments prennent presque 50% */
		padding-left: 1rem;
	}

	.info-mon-parc li:nth-child(n + 3) {
		width: 100%; /* Les éléments suivants prennent toute la largeur */
	}

	.label-mon-parc {
		font-size: 1.5rem;
	}
	.liste-mon-parc {
		padding: 0;
	}
	.fin-parc {
		font-size: 1.1669rem;
		color: var(--grisgadi);
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 2rem;
	}
	/* .liste-mon-parc:last-child {
    border-bottom: 2px solid var(--grisprez); 
  } */
}
@media screen and (min-width: 1200px) {
	.container-parc {
		padding: 6rem 2rem;
	}
}
