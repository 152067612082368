.selection-container {
  padding: 6rem 0;
  /* padding-bottom: 0; */
}
.header-selection-container {
  margin-bottom: 5rem;
  position: relative;
}
.selection-nav-slider {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
}
.selection-nav-slider button,
.selection-nav-slider button:hover {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  height: auto;
  width: auto;
}
.selection-nav-slider button span {
  direction: ltr;
  display: block;
  text-indent: -999em;
}
.selection-nav-slider i:before {
  font-size: clamp(1.6rem, 3rem, 3rem);
  color: var(--bleugadisat);
}
.selection-nav-slider i {
  margin: 0;
}
.selection-nav-slider .nav-disable i:before {
  color: var(--grisgadi);
}
.select-nav-slider-suiv {
  padding-right: 0;
}
.selection-container p {
  color: var(--grisgadi);
  font: 2.4rem;
  margin-top: 2rem;
}
.selection-produits-gris,
.selection-ref-gris,
.selection-famille-gris,
.selection-sites-partenaires-gris {
  background-color: var(--grisclair);
}
.selection-produits-blanc,
.selection-ref-blanc,
.selection-sites-partenaires {
  background-color: var(--blanc);
  /* margin-top: 15rem; */
}
.selection-produits-gris h2,
.selection-produits-blanc h2,
.selection-famille-gris h2,
.selection-sites-partenaires h2 {
  color: var(--bleugadisat);
}
.selection-ref-blanc h2 {
  color: var(--grisgadi);
}

.container-toutesFamilles h2 {
  font-family: var(--regular);
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
}
.selection-famille-gris .container {
  position: relative;
}
.header-selection-famille {
  margin-bottom: 5rem;
}
.menu {
  color: var(--bleutypofonce);
  text-decoration: none;
}
/* Ajoute le signe "+" avant le texte */
.plus-sign {
  font-family: var(--bold);
  color: var(--bleugadifonce); /* Couleur du signe "+" */
}
.item-menu-sousCategorie-Niv2 {
  font-size: 1.6rem;
  line-height: 1;
  word-wrap: break-word; /* Pour forcer le mot à se diviser s'il est trop long */
  word-break: break-all; /* Pour forcer la coupure à tout endroit du mot si nécessaire */
  white-space: normal; /* Pour que les lignes de texte passent à la ligne suivante */
}
.item-menu-sousCategorie {
  font-size: 1.6rem;
  color: var(--bleugadisat);
  letter-spacing: -0.04rem;
  line-height: 1;
  word-wrap: break-word; /* Pour forcer le mot à se diviser s'il est trop long */
  word-break: break-all; /* Pour forcer la coupure à tout endroit du mot si nécessaire */
  white-space: normal; /* Pour que les lignes de texte passent à la ligne suivante */
}
.selection-produits-gris-ref.selection-container h2{
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1669rem solid var(--grisprez);
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 899px) {
  /* .image-panel-famille h2{
    width: 70%;
  } */
}
@media screen and (max-width: 1024px) {
  .selection-produits-gris h2,
  .selection-sites-partenaires h2,
  .selection-produits-blanc h2,
  .selection-famille-gris h2,
  .selection-container h2 {
    font-size: 1.75rem;
  }
  
  .selection-container {
    padding: 2rem 0;
  }
  .selection-container p {
    font-size: 1.1669rem;
    line-height: 2rem;
    margin: 0;
  }
  .selection-moment-navigation i:before {
    color: var(--grisgadi);
  }
  .selection-moment-navigation i {
    margin: 0;
  }
  .selection-moment-navigation button {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    height: auto;
    width: auto;
  }
  .selection-moment-navigation button span {
    direction: ltr;
    display: block;
    text-indent: -999em;
  }
  .container-select-nav {
    justify-content: end;
    display: flex;
  }
  .header-selection-container {
    margin-bottom: 2rem;
  }
  .navigation-selection-moment {
    margin-top: -4rem;
  }
  .container-toutesFamilles {
    flex-direction: column;
    padding: 2rem;
  }
  .panel-toutesFamilles {
    background-color: var(--blanc);
    border: 0;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.6rem var(--noirshadow);
    display: block;
    color: var(--bleugadifonce);
    margin-bottom: 1rem;
  }
  .img-panel-toutesFamilles {
    width: 6.5rem;
    height: auto;
  }
  .image-panel-famille {
    display: flex;
    align-items: center;
    padding: 1rem;
  }
  .header-selection-famille {
    margin: 0;
  }
  a.menu {
    font-size: 1.4169rem;
  }
  .list-items-menu {
    padding-left: 5rem;
    padding-right: 3rem;
    color: var(--bleugadisat);
    font-size: 1.4169rem;
  }
  .list-items-menu li {
    padding: 1rem 0;
  }
  li.item-menu-sousCategorie-Niv2 {
    color: var(--bleugadisat);
    font-size: 1.29rem;
    line-height: 1.99rem;
  }
  p.item-menu-sousCategorie {
    font-size: 1.4169rem;
    color: var(--bleugadisat);
  }
  .image-panel-famille h2 {
    align-items: center;
    display: flex;
    text-align: left;
    justify-content: space-between;
    width: 70%;
  }
  .image-panel-famille a {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .image-panel-famille i {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-panel-famille i::before {
    color: var(--bleugadifonce);
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }
  .image-panel-famille.cfocus i::before {
    transform: rotate(180deg);
  }
  .dropdown-menu::before {
    content: "";
    display: block;
    background-color: var(--grisprez);
    height: 0.1669rem;
    position: relative;
  }
}
@media screen and (min-width: 992px) {
  .container-toutesFamilles {
    flex-wrap: wrap;
    position: relative;
  }
  .panel-toutesFamilles {
    width: 20%;
  }
  .image-panel-famille {
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-bottom: 3rem;
    padding-top: 2.5rem;
    cursor: pointer;
  }
  .image-panel-famille figure {
    margin-bottom: 2rem;
    width: 80%;
  }
  .image-panel-famille.cfocus {
    z-index: 2;
  }
  .image-panel-famille.cfocus::after {
    background-color: var(--blanc);
    content: "";
    display: block;
    height: 1.5rem;
    width: 100%;
    position: absolute;
    bottom: -1.5rem;
  }
  .image-panel-famille.cfocus,
  .dropdown-menu {
    background-color: var(--blanc);
    box-shadow: 0 0 0.6rem var(--grisgadi);
  }
  .image-panel-famille.cfocus + .dropdown-menu {
    display: block;
  }
  .dropdown-menu {
    display: none;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    cursor: pointer;
  }
  ul.list-items-menu {
    list-style: disc;
    margin: 0 auto;
    padding: 6rem 0;
    width: 90%;
  }
  ul.list-items-menu li + li {
    padding: .5rem 0;
  }
  .selection-container .list-items-menu p {
    color: var(--bleugadisat);
    line-height: 3rem;
    margin: 0;
  }
}
@media screen and (min-width: 1200px) {
  .image-panel-famille:hover img {
    transform: scale(1.1); /* Par exemple, pour agrandir l'image */
    transition: transform 0.3s ease; /* Transition douce */
  }
  
  /* Pour désactiver l'effet de survol sur l'image du dropdown actif */
  .image-panel-famille.dropdown-active img {
    transform: none; /* Aucune transformation */
  }
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
  ul.list-items-menu {
    width: 80%;
  }
}
