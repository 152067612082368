/**********/
/* FICHE PRODUIT VÉHICULE */
/**********/

.marque-modele {
	margin-left: 3rem;
}
.marque-modele > h1 span {
	display: inline-block;
	text-transform: uppercase;
	margin-right: 0.5rem;
}
.marque-modele > h1,
.marque-modele > h2 {
	color: var(--bleugadisat);
}
.info-vehicule {
	background-color: var(--grisclair);
	height: 10rem;
	position: relative;
}
.info-vehicule .container {
	align-items: center;
	display: flex;
	height: inherit;
	justify-content: space-between;
	padding: 0 2rem;
}
.icon-vehicule-selectionne::before {
	font-size: 6rem;
}
.icon-vehicule-info {
	align-items: center;
	border-radius: 0.5rem;
	display: flex;
	justify-content: center;
	margin-left: 4.5rem;
	cursor: pointer;
	color: var(--bleufond);
	background-color: var(--blanc);
	width: 5rem;
	height: 5rem;
}
.icon-vehicule-info::before {
	font-size: 2.5rem;
	height: 2.5rem;
	width: 1.1rem;
}
.info li {
	color: var(--grisgadi);
	padding-left: 2rem;
}
.info-rep li {
	padding-left: 4rem;
	padding-right: 4rem;
}
.tab-infoVehic li {
	height: 4rem;
	display: flex;
	align-items: center;
	font-size: clamp(1.5rem, 1.5rem, 1.6rem);
}
.tab-infoVehic li:nth-child(2n + 1) {
	background-color: var(--grisclair);
}
.tab-infoVehic ul {
	list-style-type: none;
}
.icon-refresh i::before{
	color: var(--bleufond);
	font-size: 2rem;
	margin-left: 2rem;
	cursor: pointer;
}
@media screen and (max-width: 1024px) {
	.icon-vehicule-selectionne::before {
		font-size: 3.7469rem;
	}
	.marque-modele {
		margin-left: 1rem;
	}
	.marque-modele > h1 {
		font-size: 1.3331rem;
	}
	.marque-modele > p {
		font-size: 1.1669rem;
	}
	.icon-vehicule-info{
    margin-left: 1.5rem;
    width: 3rem;
    height: 3rem;
	}
	.icon-vehicule-info::before {
		width: 2rem;
		height: 1.5rem;
		margin-left: 1.2rem;
		font-size: 1.5rem;
	}
	.icon-vehicule-info i::before {
		margin-left: 1rem;
		height: 3rem;
		width: 3rem;
	}
	.icon-refresh i::before{
		font-size: 1.5rem;
		margin-left: 1rem;
	}
}

@media screen and (max-width: 899px) {
	.marque-modele > h1 span {
		display: inline;
	}
}
