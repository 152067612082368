.pl .banner-pl {
	display: block;
	width: 100%;
}
.sticky.pl::before,
.pl .container-skills,
.pl .search-button-immat,
.pl .search-button-typeMine,
.pl .icon-plaque::before,
.pl .plaque-immat::after,
.pl .passer-commande-btn,
.pl .btn-deconnexion,
.pl .content-panel-modif button,
.pl .dropdown-modele.dropdown-select .icon,
.pl .icon-vehicule-info,
.pl .dropdown-modele-portail.dropdown-select-portail .icon,
.pl .top-header,
.pl .swal2-confirm{
	background-color: var(--rougepl);
}

.pl .passer-commande-btn:hover,
.pl .btn-deconnexion:hover {
	background-color: var(--rougehoverpl);
}

.pl .navigation-secondaire li a:hover,
.pl .navigation-secondaire li a.current,
.pl .infoSection p,
.pl .monPanier-text,
.pl .link-metier.current,
.pl .link-metier.current i::before{
	color: var(--rougepl);
}
.pl .tab-pane::before {
	background-color: var(--rougepl);
}

.pl .infoComplementaire::after {
	background-color: var(--rougepl);
}

.pl .btn.btn-famille {
	background-color: var(--bleugadisat);
}

