.connexion {
  background-color: var(--bleugadisat);
}
.connexion main {
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
}
.connexion main .container {
  padding: 0 2rem;
}
.connexion h1 {
  font-family: var(--medium);
  font-size: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.connexion p {
  color: var(--blanc);
  font-size: 1.5rem;
}
.connexion .col-50:first-of-type {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.connexion .col-10:first-of-type {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.connexion .nav-after::after {
  background-color: var(--bleugadifonce);
  content: "";
  display: block;
  height: 1rem;
  margin-top: 2.8rem;
  width: 100%;
}
.col-intro {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  order: 2;
  margin-top: 3rem;
}
.col-video {
  margin-top: 5rem;
}
.row-label {
  height: 0;
  width: 0;
}
.row-label label {
  direction: ltr;
  display: block;
  text-indent: -999em;
}
.row-form {
  align-items: end;
}
.row-form input {
  margin-bottom: 2rem;
}
.row-form-create {
  display: flex;
  padding-top: 2rem;
  justify-content: end;
}
.link-video {
  align-items: center;
  color: var(--grisgadi);
  display: none;
  font-size: clamp(2rem, 2.4rem, 3rem);
  padding: 20px 0 0 0;
  text-transform: uppercase;
}
.link-video i {
  margin-left: 1rem;
}
.link-video i::before {
  font-size: 3rem;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
  .row-form {
    align-items: center;
    justify-content: space-between;
  }
  .row-form input {
    margin-right: 2rem;
    margin-bottom: 0;
  }
  .row-form-create {
    justify-content: end;
  }
  .row-inputs {
    padding-bottom: 10rem;
  }
  .row-label {
    height: 0px;
  }
  .row-textuel {
    justify-content: space-between;
  }
  .row-textuel .col-md-40 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 2rem;
  }
  .link-video {
    display: flex;
  }
  .connexion h1 {
    font-size: 4rem;
    margin: 0;
    width: 80%;
  }
  .connexion h1,
  .connexion p {
    width: 80%;
  }
  .col-intro {
    order: unset;
    margin: 0;
    padding: 0;
  }
  .col-video {
    margin: 0;
  }
  .btn.btn-account {
    width: 26%;
  }
}
@media screen and (min-width: 992px) {
  .connexion .nav-after::after {
    height: 1.6rem;
    margin-top: 2.8rem;
  }
  .connexion main {
    padding: 6rem 0;
  }
}
@media screen and (min-width: 1200px) {
  .connexion h1 {
    font-size: 6rem;
  }
  .connexion main {
    padding: 10rem 0;
  }
  .connexion header::before {
    height: 2.5rem;
  }
  body.connexion header::after {
    height: 1.2rem;
  }
  .connexion .container {
    width: 58%;
  }
  .connexion input,
  .connexion button {
    width: 32%;
  }
  .nav-after .container {
    width: 84%;
  }
  .btn-account:hover {
    color: var(--bleugris);
    background-color: var(--grisgadi);
  }
  .row-form {
    align-items: center;
  }
  .link-video i::before {
    font-size: 3rem;
  }
  .row-form input {
    margin: 0;
  }
  .btn.btn-account {
    width: 32%;
  }
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
  .connexion header::before {
    height: 4rem;
  }
  .connexion header::after {
    height: 1.6rem;
  }
}
