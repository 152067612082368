.container-panier {
	padding-bottom: 5rem;
}
.container-mon-panier {
	align-items: end;
	justify-content: end;
	width: 10%;
}
.container-mon-panier * {
	margin: 0;
	padding: 0;
}
.sticky-scroll {
	position: sticky;
	top: 5rem;
}
.icon-panier {
	font-size: 70%; /* Utilise la taille de police du conteneur .panier */
}
.monPanier-text {
	color: var(--bleufond);
	cursor: pointer;
	font-family: var(--bold);
	font-size: 2rem;
	padding-left: 1rem;
	position: absolute;
	top: 0;
	width: 100%;
}
.monPanier-text-euros {
	color: var(--bleufond);
	cursor: pointer;
	/* font-size: 2.5rem; */
	font-size: 2.1rem;
	padding-left: 0.5rem;
}
.panel-code-promo{
	background-color: var(--blanc);
	box-shadow: var(--shadow);
	margin-top: 3rem;
	padding: 5rem;
}
.code-promo{
	font-size: 2.1rem;
}
.code-promo i::before{
	margin-right: 1rem;
}
.input-code-promo{
	border: 1px solid var(--grisgadi);
	border-radius: 5px;
	margin: 2rem 0;
}
.input-code-promo::placeholder{
	color: var(--grisgadi);
}
.passer-code-promo {
	align-items: center;
	background-color: var(--bleufond);
	border: none;
	border-radius: 5rem;
	color: var(--blanc);
	cursor: pointer;
	display: flex;
	height: 5.5rem;
	justify-content: center;
	margin-top: 3rem;
	margin: 0 auto;
	padding: 0 2rem;
	width: 80%;
	font-family: var(--regular);
}
.panel-mon-panier {
	background-color: var(--blanc);
	box-shadow: var(--shadow);
	margin-top: 3rem;
	padding: 5rem;
}
.titre-mon-panier {
	align-items: center;
	color: var(--bleugadisat);
	display: flex;
	font-size: 3rem;
	font-family: var(--bold);
}
.col-item-img-produit {
	display: flex;
	flex-direction: column; /* Empiler les images verticalement */
	justify-content: space-between; /* Assure un espacement régulier entre les images */
	align-items: center; /* Centrer les images horizontalement */
}
.container-imgs-produit {
	box-shadow: 0px 0px 6px #00000029;
	border-radius: 5px;
	overflow: hidden;
	padding: 0.5rem;
}
.col-item-img-produit figure {
	margin: 0;
	width: 100%;
	flex-grow: 1; /* Permet aux figures de prendre plus de place */
	display: flex;
	justify-content: center;
	align-items: center;
}
.col-item-img-produit figure:first-of-type {
	margin-bottom: 1rem;
}
.titre-mon-panier span {
	display: block;
	height: 3rem;
}
.item-mon-panier {
	box-shadow: var(--shadow);
	margin-top: 4rem;
	padding: 4rem;
	border-radius: 5px;
}
.info-item-produit {
	justify-content: space-between;
}
.sous-total-panier {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.mon-panier-icon {
	color: var(--bleugadisat);
	font-size: 5rem;
	margin-right: 2rem;
}

.panel-panierArticle {
	display: block;
	width: 100%;
	display: flex;
}

.panel-panier-article {
	box-shadow: 0px 0px 6px #00000029;
	border-radius: 5px;
	width: 17.2rem;
	height: 17rem;
	margin: 3rem;
}

.panel-monPanier:last-child {
	margin-bottom: 5rem;
}

.euro-bleuGadi {
	font-size: 5rem;
	color: var(--bleugadisat);
	font-family: var(--bold);
}

.centimes-bleuGadi {
	color: var(--bleugadisat);
	font-size: 3rem;
	position: relative;
	top: -1.5rem;
	font-family: var(--bold);
}

.ht-bleuGadi {
	font-size: 3rem;
	color: var(--bleugadisat);
	position: relative;
	top: -1.5rem;
	font-family: var(--light);
}

.nom-produit-panier {
	margin-top: 3rem;
	margin-bottom: 2rem;
	margin-left: 1rem;
}

.global-info-panier {
	margin-top: 1rem;
}
.stock-mag {
	color: var(--vert00B100);
	font-family: var(--regular);
}
.stock-plat {
	color: var(--bleufond);
	font-family: var(--regular);
}
.stock-cmd {
	color: var(--jauneE6AD00);
	font-family: var(--regular);
}

.ref-stock-panier {
	flex-direction: column;
}
.stock-panier {
	margin-top: 1rem;
}
.sous-total {
	font-size: clamp(2rem, 2.1rem, 2.2rem);
}
.passer-commande-btn {
	align-items: center;
	background-color: var(--bleufond);
	border: none;
	border-radius: 5rem;
	color: var(--blanc);
	cursor: pointer;
	display: flex;
	height: 5.5rem;
	justify-content: center;
	margin-top: 3rem;
	padding: 0 2rem;
	width: 100%;
	font-family: var(--regular);
}

/* .passer-commande-btn:hover {
	background-color: #0056b3; 
} */
.prix-commentaire .passer-commande-btn {
	width: 100%;
}

.fonction-article-attente {
	align-items: center;
	margin-top: 1.8rem;
	justify-content: center;
}
.fonction-article-attente button {
	font-family: var(--regular);
	position: relative;
}
.fonction-article-attente button:after {
	background-color: var(--grisgadi);
	content: "";
	display: block;
	height: 2.6rem;
	position: absolute;
	right: 0;
	width: 0.2rem;
}
.fonction-article-attente button:last-child:after {
	display: none;
}

.fonction-mon-panier {
	align-items: center;
	justify-content: space-between;
	margin-top: 1.8rem;
}
.fonction-mon-panier button {
	font-family: var(--regular);
	position: relative;
}
.fonction-mon-panier button:after {
	background-color: var(--grisgadi);
	content: "";
	display: block;
	height: 2.6rem;
	position: absolute;
	right: 0;
	width: 0.2rem;
}
.fonction-mon-panier button:last-child:after {
	display: none;
}
.qte-panier {
	background-color: var(--grisclair); /* Couleur de fond du bouton */
	color: var(--bleugadistat); /* Couleur du texte */
	border: none; /* Pas de bordure */
	padding: 1rem 2rem; /* Espacement interne pour ressembler à un bouton */
	border-radius: 0.5rem; /* Coins arrondis */
	font-size: 1.6rem; /* Taille du texte */
	cursor: pointer; /* Curseur en forme de main pour imiter un bouton */
	outline: none; /* Supprime le contour lors du focus */
	width: 13.2rem;
	height: 4.3rem;
	font-family: var(--regular);
}

.qte-panier:hover {
	background-color: var(--grisprez); /* Changer la couleur au survol */
}

.qte-panier:focus {
	box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Met en évidence lors du focus */
}

.supprimer,
.cote,
.ajout-mes-listes {
	color: var(--grisgadi);
	background-color: transparent;
	font-size: clamp(1.5rem, 1.6rem, 1.7rem);
	cursor: pointer;
}

.colonne {
	display: flex;
	align-items: baseline;
}
.mes-listes-panier {
	margin-top: 3rem;
	padding: 3rem;
	background-color: var(--blanc);
}
.titre-mes-listes {
	font-size: 2.5rem;
	color: var(--bleugadisat);
	font-family: var(--bold);
}
.listes-mes-listes {
	height: 66px;
	border: 1.5px solid var(--grisprez);
	border-radius: 5px;
	margin-top: 3rem;
	cursor: pointer;
}
.icon-listes {
	margin-right: 2rem;
	margin-left: 2rem;
	font-size: 3rem;
	display: flex;
	align-items: center;
}
.texte-listes {
	margin-bottom: 2rem;
	margin-top: 2.5rem;
	align-items: center;
}
.commentaire-prix-mon-panier {
	margin-top: 4rem;
}
.commentaire {
	width: 789px;
	height: 186px;
	display: flex;
	flex-direction: column;
}

textarea {
	flex: 1;
	resize: none;
	width: 100%;
	height: 100%;
	padding: 10px;
	font-size: 16px;
	box-sizing: border-box;
	border: 2px solid var(--grisprez);
	border-radius: 5px;
	font-family: var(--regular);
	padding-left: 3rem;
	padding-top: 2rem;
	color: var(--bleugadisat);
}
.prix-mon-panier {
	margin-top: 1.5rem;
}
.prix-commentaire {
	justify-content: center;
	margin-left: 10rem;
}

.contour-blanc-attente {
	width: 123rem;
	height: auto;
	margin-bottom: 5rem;
	margin-left: 14rem;
	background-color: var(--blanc);
}

.attente {
	color: var(--bleugadisat);
	font-size: 4rem;
	display: flex;
	margin-right: 2rem;
}

.texte-attente {
	font-size: 3rem;
	color: var(--bleugadisat);
	margin-top: 3rem;
	font-family: var(--bold);
}

.panel-articles-attente {
	display: inline-block;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch; /* Défilement fluide sur iOS */
	margin-right: 5rem;
}

.panel-articleAttente {
	width: 35rem;
	background-color: #ffffff;
	border: 0px;
	border-radius: 5px;
	box-shadow: 0px 0px 6px #00000029;
	opacity: 1;
	display: inline-block;
	margin-top: 3rem;
}

.list-items-mon-panier .row {
	width: 100%;
	margin-left: 0;
	margin-right: 0;
}

/* Dernier élément, si spécifique besoin */
.panel-articleAttente:last-child {
	margin-right: 2rem;
}
/* Applique un margin-bottom de 5rem uniquement à la dernière ligne */
.panel-articleAttente:nth-last-child(-n + 3) {
	margin-bottom: 1rem;
}

.img-articles-attente {
	width: 17.2rem;
	height: 19.5rem;
	margin-top: 3rem;
	margin-bottom: 2rem;
}

.figure-articleAttente {
	display: flex;
	justify-content: center;
}

.libelle-article-attente {
	display: flex;
	/* justify-content: center; */
	font-size: clamp(2rem, 2.1rem, 2.2rem);
	align-items: center;
	margin-left: 5rem;
	margin-right: 5rem;
}

.marginTop1 {
	margin-top: 1.5rem;
}

.euro-bleugadisat {
	color: var(--bleugadisat);
	font-size: 3rem;
	margin-top: 1rem;
	font-family: var(--bold);
}

.centimes-bleugadisat {
	color: var(--bleugadisat);
	font-size: 2rem;
	position: relative;
	top: -1rem;
	font-family: var(--bold);
}

.ht-bleugadisat {
	color: var(--bleugadisat);
	position: relative;
	top: -1rem;
	font-size: 2rem;
	font-family: var(--regular);
}

.vert-article-attente {
	color: var(--vert00B100);
	font-family: var(--regular);
	margin-top: 1.5rem;
	margin-left: 5rem;
	font-size: clamp(1.5rem, 1.6rem, 1.7rem);
	margin-bottom: 2rem;
}

.deplacer-panier-btn {
	width: 255px;
	height: 41px;
	border: 2px solid var(--bleutypofonce);
	border-radius: 10px;
	font-size: clamp(1.5rem, 1.6rem, 1.7rem);
	background-color: var(--blanc);
	color: var(--bleutypofonce);
	margin-top: 2rem;
}

.deplacer-panier-btn:hover {
	background-color: var(--grisprez);
}

.contour-blanc-mes-listes-panier {
	width: 123rem;
	height: auto;
	margin-bottom: 5rem;
	margin-left: 14rem;
	background-color: var(--blanc);
}

.texte-titre-mes-listes-panier {
	font-size: 3rem;
	color: var(--bleugadisat);
	margin-top: 3rem;
	font-family: var(--bold);
	margin-bottom: 3rem;
}

.panel-mesListes-panier {
	border-radius: 0px;
	height: 6rem;
	background-color: var(--grisclair);
	margin-bottom: 1rem;
	margin-top: 3rem;
	cursor: pointer;
}

.panel-ajout-liste {
	border: 1px solid var(--grisprez);
	height: 6.6rem;
	border-radius: 5px;
	display: flex;
	align-items: center;
}

.article-dans-mes-listes {
	color: var(--grisgadi);
}
.bouton-supprimer-ajout-attente {
	margin-bottom: 3rem;
}

.libelle-mes-listes-articles {
	align-items: center;
}

.article-meslistes {
	align-items: center;
}

.ajouter-une-liste {
	background-color: transparent;
	color: var(--grisgadi);
	font-family: var(--regular);
	font-size: clamp(1.5rem, 1.6rem, 1.7rem);
}

.message-panier-vide {
	font-size: 3rem;
	padding: 3rem 0;
}
.prix-mon-panier .ht {
	font-size: 4.6rem;
}

.list-items-mon-panier {
	width: 100%;
}

.devis-btn{
	font-size: 1.5rem;
	align-items: center;
	background-color: transparent;
	border: 1px solid var(--bleugadisat);
	border-radius: 5rem;
	color: var(--bleugadisat);
	cursor: pointer;
	display: flex;
	height: 5.5rem;
	justify-content: center;
	margin-top: 1rem;
	padding: 0 2rem;
	width: 100%;
	font-family: var(--regular);
}

.devis-btn i::before{
	padding-right: 1rem;
	font-size: 1.7rem;
	align-items: center;
	display: flex;
}

.commande-btn-container i::before {
	/* padding-right: 1rem; */
	font-size: 1.5rem;
	align-items: center;
	display: flex;
}

.commande-btn-container {
	flex-direction: column;
}

input.reference-commande {
  border: 1px solid var(--bleufond);
  border-left: none;
  border-radius: 0 5px 5px 0;
}

.btn-commande-devis{
	flex-direction: column;
	width: 80%;
}

.commentaire-hidden {
  visibility: hidden; /* Le textarea est caché mais occupe toujours de l'espace */
  height: auto; /* Conserve sa hauteur dynamique */
}

.commentaire-visible {
  visibility: visible; /* Le textarea est visible */
}

@media screen and (max-width: 576px) {
	.monPanier-text {
		width: 100%;
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (max-width: 1024px) {
	.selection-produits-gris.mobile {
		background-color: var(--blanc);
	}
	.monPanier-text {
		font-size: 1.4rem;
		/* width: 50%; */
		top: 0.8rem;
		padding-left: 0.7rem;
	}
	.icon-plaque::before {
		font-size: 3.5rem;
	}

	.mobile-sous-total {
		background-color: var(--grisclair);
		width: 100vw;
		margin-left: calc(-50vw + 50%);
		border-radius: 5px;
		box-sizing: border-box;
	}
	.panel-mon-panier.mobile {
		background-color: transparent;
		box-shadow: none;
		margin: 0;
		padding: 1rem 3rem;
		justify-content: space-between;
		align-items: baseline;
	}
	.sous-total-panier {
		flex-direction: row;
	}
	.sous-total {
		font-size: 1.33rem;
	}
	.panel-mon-panier .mobile h4.prix-mon-panier {
		font-size: 2.5rem;
	}
	.prix-mon-panier {
		margin: 0;
	}
	.prix-mon-panier .ht {
		font-size: 2.5rem;
	}
	.prix-mon-panier .ht sup {
		font-size: 1.5rem;
	}
	.passer-commande-btn {
		width: 100%;
		margin: 0;
		margin-right: 1rem;
	}

	.panel-mon-panier {
		background-color: transparent;
		box-shadow: none;
		margin: 0;
		padding: 2rem 0;
		width: 100%;
	}
	.item-mon-panier {
		box-shadow: none;
		margin: 0;
		padding: 0;
	}
	.col-item-img-produit {
		max-width: 30%;
	}
	.img-marque {
		margin: 0;
	}
	.list-items-mon-panier .row {
		gap: 1rem;
	}
	.title-ref-item-produit h3 {
		font-size: 1.33rem;
	}
	.ref {
		font-size: 1.17rem;
	}
	.stock-panier {
		margin: 0;
	}
	.stock-panier p {
		font-size: 1.33rem;
	}
	.info-item-produit {
		flex-direction: column;
	}
	.prix-qte {
		justify-content: space-between;
		align-items: center;
	}
	.qte-panier {
		width: 7.2rem;
	}

	.prix-qte .prix .ht span {
		font-size: 2rem;
	}
	.prix-qte .prix .ht sup {
		color: var(--bleugadisat);
		font-size: 1.5rem;
	}
	.supprimer,
	.cote {
		font-size: 1.17rem;
	}
	.article-suivant {
		position: relative;
		margin-bottom: 3rem;
	}
	.article-suivant::after {
		content: "";
		position: absolute;
		bottom: -17px; /* Ajuste selon la distance que tu veux avec le contenu */
		left: 0;
		width: 100%;
		height: 4px; /* Épaisseur de la ligne */
		background-color: var(--grisprez); /* Couleur de la ligne */
	}
	.container-panier {
		padding: 0 2rem;
	}
	.message-panier-vide {
		font-size: 2rem;
	}
	.commentaire-mon-panier{
		padding-bottom: 2rem;
	}
	textarea{
		padding: 1rem;
	}
	.code-promo {
    font-size: 1.5rem;
	}
	.input-code-promo{
		width: 80%;
	}
	.panel-code-promo{
		background-color: var(--bleucodepromo);
		padding: 2rem;
		margin: 0;
	}
	.panel-code-promo h2.texte-code-promo{
		font-size: 1.5rem;
	}
	.total-panier{
		padding: 1.5rem;
	}
	.texte-code-promo{
		justify-content: space-between;
		align-items: center;
	}
	.texte-code-promo i::before{
		font-size: 1rem;
	}
	.btn-commande-devis{
		align-items: end;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
}
@media screen and (min-width: 1200px) {
	/* .container-mon-panier {
		width: 10%;
	} */
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
