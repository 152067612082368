.header-panel {
	padding: 2rem 0;
	width: 100%;
}

.immat-ref {
	margin-top: 1rem;
}

.immat-ref .nav-tabs {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
}
.immat-ref .nav-link {
	color: var(--grisgadi);
}
.immat-ref .nav-item > .nav-link {
	border-radius: 0.5rem 0.5rem 0 0;
	text-transform: uppercase;
	width: 100%;
	font-family: var(--regular);
	position: relative;
}
.immat-ref .nav-item:first-child > .nav-link {
	margin: 0;
	width: calc(100% + 0.5rem);
}
.immat-ref .nav-item:nth-child(2n) > .nav-link.active {
	border-radius: 0.5rem 0.5rem 0 0;
	position: relative;
	width: 100%;
	z-index: 1;
}
.immat-ref .nav-item:last-child > .nav-link {
	margin-left: -0.5rem;
	border-top-right-radius: 0.5rem;
	width: calc(100% + 0.5rem);
}
.immat-ref .nav-item:last-child > .nav-link.active {
	margin-left: -0.5rem;
	border-radius: 0.5rem 0.5rem 0 0;
	border-top-right-radius: 0.5rem;
	width: calc(100% + 0.5rem);
}
.immat-ref .nav-item:first-child > .nav-link:before,
.immat-ref .nav-item:last-child > .nav-link:before {
	content: "";
	background-color: var(--grisgadi);
	height: 100%;
	width: 0.5rem;
	position: absolute;
}
.immat-ref .nav-item:first-child > .nav-link:before {
	right: 0;
	z-index: 1;
}
.immat-ref .nav-item:first-child > .nav-link.active:before {
	display: none;
}
.immat-ref .nav-item:last-child > .nav-link:before {
	left: 0;
}
.immat-ref .nav-item:last-child > .nav-link.active:before {
	display: none;
}
.immat-ref .nav-item > .nav-link.active {
	background-color: var(--bleugadisat);
	border-top-left-radius: 0.5rem;
	color: white;
	margin: 0;
	z-index: 1;
}
.immat-ref .nav-item {
	border: none;
	list-style: none;
	width: calc(33.33% + 2px);
}

.immat-ref .nav-item.disable {
	background-color: var(--grisprez);
	margin: 0 -0.2rem;
}

.immat-ref .nav-item.disable:last-child {
	border-left: 0.5rem solid var(--grisgadi);
	border-top-right-radius: 0.5rem;
}

.immat-ref .nav-item.active {
	background-color: var(--bleugadisat);
	border-radius: 0.5rem 0.5rem 0 0;
	position: relative;
	z-index: 1;
}
.immat-ref .col-50 {
	margin: 0;
}
.plaque-immat {
	border-radius: 0.5rem;
	height: 7rem;
	overflow: hidden;
}
.icon-plaque {
	background-color: var(--bleufond);
	border: none;
	color: var(--blanc);
	display: flex;
	width: 6rem;
	z-index: 1;
}

.icon-plaque::before {
	align-items: center;
	display: flex;
	font-size: 4.5rem;
	position: relative;
	justify-content: center;
	width: 100%;
}

.input-container {
	display: flex;
	align-items: center;
}

.input-control-maj {
	border-radius: 0;
	height: 100%;
	text-transform: uppercase;
	font-size: 3rem;
	letter-spacing: 2px;
	width: 100%;
}
.input-control-maj::placeholder {
	color: var(--grisgadi);
	text-align: center;
	letter-spacing: 2px;
}
.input-control-typeMine-maj {
	border-radius: 5px;
	height: 7rem;
	text-transform: uppercase;
	font-size: clamp(2.9rem, 3rem, 3.1rem);
}

.plaque-immat::after {
	content: "";
	display: block;
	background-color: var(--bleufond);
	/* border-radius: 0 0.5rem 0.5rem 0; */
	height: 100%;
	width: 2rem;
}

.search-button,
.search-button-ref {
	align-items: center;
	border: none;
	border-radius: 5px;
	display: flex;
	font-size: clamp(1.6rem, 1.8rem, 2rem);
	justify-content: center;
	height: 7rem;
	width: 26.8rem;
}

.search-header {
	border-radius: 0 0.5rem 0.5rem 0;
	height: 5rem;
	width: 7rem;
}

.search-header i {
	margin: 0;
}

.search-button-immat,
.search-button-typeMine,
.search-button-modele {
	background-color: var(--bleufond);
}

.search-button-ref {
	background-color: var(--bleugadisat);
	height: 6rem;
}

.search-button-immat,
.search-button-typeMine,
.search-button-modele,
.search-button-ref {
	color: var(--blanc);
	margin-left: 2rem;
}

.input-control-typeMine {
	width: 35.4rem;
	height: 5.8rem;
	text-transform: uppercase;
	margin: 2rem 6rem 2rem 6rem;
	letter-spacing: 2.4px;
	font-size: clamp(2rem, 2.5rem, 3rem);
	position: relative;
}

.input-ref {
	height: 6rem;
}

.input-group {
	display: flex;
	align-items: center;
	border-radius: 4px 0 0 4px;
	overflow: hidden;
}

.input-group-modif {
	display: flex;
	align-items: center;
	margin-left: 3.5rem;
}

.input-group-modif input {
	border: none;
	outline: none;
	flex: 1;
	border-radius: 0px;
	width: 25rem;
	height: 5.8rem;
}

.input-group .icon-container {
	background-color: #2175ff;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.input-group .end-container {
	background-color: #2175ff;
	color: white;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 19px;
	height: 70px;
	transform: matrix(-1, 0, 0, -1, 0, 0);
	border-radius: 4px 0 0 4px;
}

.input-group .icon-container a {
	color: white;
	text-decoration: none;
}

.input-group input {
	border: none;
	outline: none;
	flex: 1;
	border-radius: 0 0 0 0;
}

.input-group input::placeholder {
	color: var(--grisgadi);
	font-size: 3rem;
}

.input-group-ref {
	width: 100%;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.autocomplete-container {
	position: relative;
	margin: 0 auto;
}

.autocomplete-input {
	width: 30%;
	padding: 0 1.5rem 0 1rem;
	box-sizing: border-box;
}

.input-group {
	margin: 1rem;
	display: flex;
	align-items: center;
	border-radius: 4px 0 0 4px;
	overflow: hidden;
}

.input-group input {
	border: none;
	outline: none;
	flex: 1;
	border-radius: 0px;
}

.input-group .icon-container {
	background-color: #2175ff;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.input-group .end-container {
	background-color: #2175ff;
	color: white;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 19px;
	height: 70px;
	transform: matrix(-1, 0, 0, -1, 0, 0);
	border-radius: 4px 0 0 4px;
}

.input-group .icon-container a {
	color: white;
	text-decoration: none;
}

.input-group input::placeholder {
	color: var(--grisgadi);
	font-size: 20px;
}

.panel-modif-immat {
	background-color: var(--grisclair);
	border: 0px;
	/* box-shadow: 0px 0px 6px var(--bleufondfonce); */
	box-shadow: 0px 0px 6px var(--noirshadow);
	border-radius: 0 0 0.5rem 0.5rem;
	width: 100%;
	height: auto;
	padding: 4rem 3rem;
	position: absolute;
	/* Positionner absolument par rapport à .info-voiture */
	z-index: 10;
	/* z-index élevé pour être au-dessus */
}

.header-modif {
	align-items: end;
	display: flex;
}

.header-modif .icon {
	font-size: clamp(3rem, 6rem, 8rem);
	margin-right: 1.5rem;
}

.tab-pane,
.panel-ref {
	padding: 4rem;
}

.panel-ref {
	background-color: var(--grisprez);
	border: 0;
	border-radius: 0.5rem;
	transition: all ease-in-out 0.3s;
}
.show-results {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.show-results .search-text {
	display: none;
	opacity: 0;
}
.tab-pane {
	background-color: var(--grisprez);
	border: 0;
	border-radius: 0 0 0.5rem 0.5rem;
	position: relative;
}

.tab-pane::before {
	background-color: var(--bleufond);
	content: "";
	display: block;
	height: 0.5rem;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.tab-pane.active {
	background-color: var(--bleugadisat);
}

/* .tab-pane .recherche-modele {
  margin-top: 4.4rem;
} */

.icon-immat,
.icon-changer-mine,
.icon-changer-selection {
	margin-right: 4rem;
}

.icon-immat::before,
.icon-changer-mine::before,
.icon-changer-selection::before {
	font-size: 6rem;
}
.search-plaque-immat,
.search-ref-produit {
	margin-top: 2.5rem;
}
.search-mine {
	margin-top: 3.2rem;
}
.search-modele {
	width: 30%;
}
.search-modele .dropdown-modele-portail.dropdown-select-portail {
	margin: 0;
}
label {
	display: block;
	/* text-indent: -999em; */
}
h2.num-immat {
	color: var(--blanc);
	display: flex;
	align-items: baseline;
	text-transform: uppercase;
}
h2.portail-mine,
h2.portail-modele {
	color: var(--blanc);
	display: flex;
	align-items: baseline;
	text-transform: uppercase;
}
h2.achat-rapide {
	align-items: baseline;
	color: var(--grisgadi);
	display: flex;
	text-transform: uppercase;
}
h2.achat-rapide i {
	margin-right: 4rem;
}
h2.num-immat,
h2.achat-rapide,
h2.portail-mine,
h2.portail-modele {
	font-size: 2rem;
}
h2.num-immat i::before,
h2.achat-rapide i::before,
h2.portail-mine i::before,
h2.portail-modele i::before {
	color: var(--blanc);
	font-size: 6rem;
}
h2.achat-rapide i::before {
	font-size: 4rem;
}
h2.achat-rapide span {
	display: inline-block;
	margin: 0.6rem;
}

.clear-btn {
	position: relative;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
	background: none;
	border: none;
	font-size: 3rem;
	cursor: pointer;
	color: var(--bleugadisat);
	padding: 6rem 0 0 1.5rem;
	margin-left: -10rem;
}
.results-search-ref-produits {
	overflow: hidden;
	opacity: 1;
	transition: none;
}
@media screen and (max-width: 576px) {
	.icon-plaque::before {
		font-size: 3.5rem;
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 769px) {
}
@media screen and (max-width: 899px) {
	.clear-btn {
		padding: 5.5rem 0 0 1.5rem;
	}
	.search-button-ref {
		border-radius: 0 5px 5px 0;
	}
}
@media screen and (max-width: 1024px) {
	.search-text {
		display: none;
	}
	.autocomplete-input {
		width: 100%;
	}
	.tab-pane,
	.panel-ref {
		padding: 2rem 1rem;
	}

	.plaque-immat,
	.search-button,
	.tab-pane input,
	.panel-ref input {
		height: 5rem;
	}
	.icon-plaque {
		width: 4rem;
	}
	.search-button {
		width: 5rem;
		/* border-radius: 0 5px 5px 0; */
	}
	.search-button i {
		margin-right: 0;
	}
	.search-plaque-immat,
	.search-ref-produit {
		margin-top: 2rem;
	}
	h2.num-immat,
	h2.achat-rapide,
	h2.portail-mine,
	h2.portail-modele {
		font-size: 1.5rem;
	}
	h2.num-immat i,
	h2.achat-rapide i,
	h2.portail-mine i,
	h2.portail-modele i {
		margin-right: 1.5rem;
	}
	h2.num-immat i::before {
		font-size: 3rem;
	}
	h2.num-immat i::before,
	h2.portail-mine i::before,
	h2.portail-modele i::before {
		font-size: 4rem;
	}
	h2.achat-rapide i::before {
		font-size: 1.5rem;
	}
	.input-control-maj {
		font-size: 2.5rem;
	}
	.recherche-modele {
		flex-direction: column;
	}
	.tab-pane .recherche-modele {
		margin-top: 0.4rem;
	}
	.search-modele {
		width: 100%;
		justify-content: center;
		margin-top: 1.5rem;
	}
	.immat-ref {
		flex-direction: column;
		padding: 0;
	}
	.ref-carte {
		padding: 0 2rem;
	}
	.immat-ref .nav-item > .nav-link {
		font-size: 1.1669rem;
		height: 3rem;
	}
}

@media screen and (min-width: 992px) {
	h2.achat-rapide {
		display: unset;
	}
	.immat-ref {
		margin-top: 3rem;
	}
	.search-text {
		display: none;
	}
	.search-button {
		width: 5rem;
	}
	.search-button i {
		margin: 0;
	}
	.recherche-input {
		margin-right: 1.5rem;
		width: 16%;
	}
	.recherche-input:last-child {
		margin-right: 0;
	}
}
@media screen and (min-width: 1200px) {
	.immat-ref {
		margin: 8rem auto;
		margin-bottom: 0;
	}
	.immat-ref-container {
		display: flex;
		justify-content: space-between;
		/* margin-bottom: 8rem; */
	}
	.selection-famille-gris {
		margin-top: 8rem;
	}
	h2.achat-rapide {
		display: flex;
	}
	h2.num-immat,
	h2.mine,
	h2.modele {
		letter-spacing: 0.1rem;
	}

	.plaque-immat,
	.input-ref,
	.input-control-typeMine-maj {
		width: calc(100% - 26.8rem);
	}
	h2.num-immat,
	h2.achat-rapide {
		/* font-family: var(--medium); */
		font-size: 2rem;
	}
	.tab-pane .recherche-modele {
		margin-top: 4.4rem;
	}
	.search-button {
		width: 35%;
	}
	.search-text {
		opacity: 1;
		display: block;
	}
	.search-button i {
		margin: 1.5rem;
	}
	.search-button.search-header {
		width: 6rem;
	}
	.ref-carte {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

@media screen and (min-width: 1400px) {
}

@media screen and (min-width: 1800px) {
}
