/* FORMULAIRES */
input {
	background: var(--blanc);
	border: none;
	border-radius: 0.5rem;
	box-shadow: none;
	color: var(--bleutypofonce);
	font-size: 1.5rem;
	width: 100%;
}
input:hover,
input:focus {
	background: var(--blanc);
}
input:hover,
button:hover {
	cursor: pointer;
}
::placeholder {
	color: var(--bleutypofonce);
	opacity: 1;
}
/* BOUTONS */
input,
button {
	align-items: center;
	border-radius: 0.3125rem;
	display: flex;
	height: 5rem;
	padding: 0 1.5rem;
}
button {
	font-family: var(--bold);
	justify-content: center;
}
button i {
	font-size: 2rem;
	/* margin-right: 2rem; */
}
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
input[type="number"]::-moz-inner-spin-button {
	-moz-appearance: none;
}
.btn {
	align-items: center;
	background-color: var(--bleufond);
	border: none;
	border-radius: 0.5rem;
	color: var(--blanc);
	cursor: pointer;
	display: flex;
	justify-content: center;
	width: 75%;
}
.btn-outline {
	background-color: transparent;
	border: 0.1rem solid #a9aeb6;
	color: #a9aeb6;
}
.btn-plain {
	color: #fff;
}
.btn-2175FF {
	background-color: #2175ff;
}
.btn-041D5B {
	background-color: var(--bleugadisat);
}
.search-button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	height: 5rem;
	font-family: var(--medium);
	font-size: 1.6rem;
	margin-left: -1rem;
	width: 7rem;
}
.btn-famille {
	width: 100%;
}
@media screen and (max-width: 576px) {
	button i {
		font-size: 1.8rem;
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
	button {
		transition: all 0.3s ease-in-out;
	}
	button {
		width: unset;
	}
	.btn:hover {
		background: var(--bleufondhover);
		color: var(--blanc);
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
