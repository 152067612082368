.container-listes{
  padding: 6rem 0;
}
.panel-mes-listes{
	background-color: var(--blanc);
  box-shadow: var(--shadow);
  padding: 2rem 4rem 4rem 4rem;
}
.mes-listes{
  width: 100%;
  align-items: center;
}
.icon-mes-listes{
  padding-bottom: 4rem;
}
.icon-mes-listes i::before{
	color: var(--bleugadisat);
	font-size: 4rem;
	display: flex;
  padding-right: 4rem;
}
.panel-mesListes{
  width: 100%;
  background-color: var(--grisclair);
  display: flex;
  padding: 1rem 4rem 1rem 2rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.texte-article{
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.texte-article i::before{
  padding-left: 4rem;
  color: var(--grisgadi);
  cursor: pointer;
}
.article-fleche-point i:nth-of-type(1)::before {
  font-size: 1.5rem;
}
.article-fleche-point i:nth-of-type(2)::before {
  font-size: 2rem;
}
.mes-listes > i::before{
  font-size: 4rem;
  display: flex;
}
p.nombres-articles{
  font-size: 1.6rem;
  color: var(--grisgadi);
}

.texte-titre-mes-listes{
  font-size: 3rem;
  color: var(--bleugadisat);
  font-family: var(--bold);
}

.texte-mes-listes{
  font-size: 1.6rem;
  color: var(--bleugadisat);
  font-family: var(--bold);
  padding-left: 4rem;
}

.icon-mes-listes{
  align-items: center;
}

.article-fleche-point{
  align-items: center;
}

.panel-ajouter-liste{
  cursor: pointer;
  padding: 1rem 4rem 1rem 2rem;
  border : 2px solid var(--grisprez);
  border-radius: 5px;
}
.texte-ajouter-listes{
  font-size: 1.6rem;
  color: var(--grisgadi);
  padding-left: 4rem;
}
.notificationListe {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: none;
  z-index: 20;
  height: 50vh;
}
.notification-creation-liste{
  font-size: clamp(3rem, 3rem, 3.9rem);
	margin: 10px 0;
	color: var(--bleugadisat);
  flex-direction: column;
  align-items: center;
}
.utilite-liste{
  font-size: 2.1rem;
}
.notification-creation-liste h2.texte-creation-liste {
  color: var(--bleutypofonce);
  font-size: 2.33rem;
  font-family: var(--bold);
  display: flex;
  align-items: end;
  padding-bottom: 2rem;
}
@media screen and (max-width: 1024px){
  .panel-mes-listes {
    background-color: transparent;
    box-shadow: none;
    padding: 2rem 0;
  }
  .container-listes {
    padding: 0 2rem;
  }
  .icon-mes-listes {
    padding-bottom: 2rem;
  }
  .icon-mes-listes i::before{
    padding-right: 2rem;
    font-size: 2.75rem;
  }
  .texte-titre-mes-listes{
    font-size: 2rem;
  }
  .panel-mesListes {
    background-color: transparent;
    border: 2px solid var(--grisprez);
    padding: 1rem 2rem 1rem 2rem;
  }
  .texte-mes-listes{
    padding-left: 2rem;
    font-family: var(--regular);
  }
  .texte-ajouter-listes{
    padding-left: 2rem;
  }
  .mes-listes > i::before{
    font-size: 3rem;
  }
  /* .notificationListe{
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: none;
    z-index: 20;
    height: 50vh;
  } */
  .notification-creation-liste h2.texte-creation-liste{
    color: var(--bleutypofonce);
    font-size: 2.33rem;
    font-family: var(--bold);
    justify-content: space-between;
    display: flex;
    align-items: end;
    padding-bottom: 2rem;
    width: 100%;
  }
  .notification-creation-liste{
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
  .close-btn-liste{
    color: var(--grisgadi);
    font-size: 3.68rem;
  }
  .utilite-liste{
    color: var(--bleutypofonce);
  }
  .utilite-liste span{
    align-items: flex-start;
    display: flex;
  }
  .utilite-liste {
    font-size: 1.17rem;
    padding-bottom: 4rem;
  }
  .reduced-padding {
    padding-bottom: 1.5rem; /* Padding réduit si l'alerte est active */
  }
  .feuille {
    gap: 1rem;
  }

  .feuille i {
    font-size: 2.34rem;
  }

  .feuille-nom-liste {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem; /* Ajoute un espace de 1rem entre .feuille et .nom-liste */
  }

  .nom-liste {
    border: 1px solid var(--grisgadi);
    border-radius: 5px;
  }
  .nom-liste::placeholder{
    color: var(--grisgadi);
    font-size: 1.5rem;
  }

  .nom-liste-bouton{
    flex-direction: column;
  }

  .btn-creer-liste{
    background-color: var(--bleufond);
    color: var(--blanc);
    margin-top: 2rem;
  }
  .icones-toggle-btn {
    font-size: 2.34rem;
    color: var(--grisgadi);
    background-color: var(--blanc);
    border: 1.5px solid var(--grisgadi);
    border-radius: 5px;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .selected-icon {
    color: var(--bleutypofonce); /* Couleur plus sombre pour l'icône sélectionnée */
  }
  .menu-smart.icones {
    background-color: var(--blanc);
    box-shadow: -0.2rem 0px 1.2rem 0.6rem var(--noirshadow);
    height: 100vh;
    width: 100%;
    padding: 0;
  }
  .header-panel-icones {
    border: none;
    padding: 1rem 1rem 0rem 1rem;
  }
  .menu-header-icones {
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    position: relative;
  }
  .icones-choisir {
    padding: 1rem 1rem;
  }
  .nav-groupedIcones{
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(100%);
    transition: all 0.5s ease-in-out;
    width: 100%;
    z-index: 30;
  }
  .menu-title-icone{
    font-size: 1.5rem;
    font-family: var(--regular);
  }
  .choisir-icone-title i::before{
    font-size: 2.76rem;
  }
  .choisir-icone-title{
    padding-left: 0.8rem;
    width: 30%;
    justify-content: space-between;
    align-items: center;
  }
  .choisir-icones {
    padding: 0 1rem 0 1rem;
  }
  .panel-choisir-icones {
    background-color: var(--grisclair);
    padding: 1rem;
    position: relative;
  }
  .panel-choisir-icones::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.416rem;
    background-color: var(--bleufond);
  }
  .titre-choisir-icones {
    text-transform: uppercase;
    color: var(--bleugadisat);
    /* font-size: 1.17rem; */
    font-size: 1.3rem;
    letter-spacing: 0.029rem;
  }
  .icones-choisir{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .icones-choisir i {
    margin: 1rem 0 2rem 0;
  }
  .case1 { grid-area: 1 / 1 / 2 / 2; }
  .case2 { grid-area: 2 / 1 / 3 / 2; }
  .case3 { grid-area: 3 / 1 / 4 / 2; }
  .case4 { grid-area: 4 / 1 / 5 / 2; }
  .case5 { grid-area: 1 / 2 / 2 / 3; }
  .case6 { grid-area: 2 / 2 / 3 / 3; }
  .case7 { grid-area: 3 / 2 / 4 / 3; }
  .case8 { grid-area: 4 / 2 / 5 / 3; }
  .case9 { grid-area: 1 / 3 / 2 / 4; }
  .case10 { grid-area: 2 / 3 / 3 / 4; }
  .case11 { grid-area: 3 / 3 / 4 / 4; }
  .case12 { grid-area: 4 / 3 / 5 / 4; }
  .case13 { grid-area: 1 / 4 / 2 / 5; }
  .case14 { grid-area: 2 / 4 / 3 / 5; }
  .case15 { grid-area: 3 / 4 / 4 / 5; }
  .case16 { grid-area: 4 / 4 / 5 / 5; }
  .case17 { grid-area: 1 / 5 / 2 / 6; }
  .case18 { grid-area: 2 / 5 / 3 / 6; }
  .case19 { grid-area: 3 / 5 / 4 / 6; }
  .case20 { grid-area: 4 / 5 / 5 / 6; }

  .icones-choisir i::before{
    font-size: 2.45rem;
    /* font-size: 3.45rem; */
    padding-right: 2rem;
    margin-top: 2rem;
  }

  .alert-message {
    color: var(--rougepl);
    font-size: 1.33rem;
    font-style: italic;
    padding-bottom: 1rem;
  }
  
  .alert-border {
    border: 2px solid var(--rougepl); /* Bordure rouge pour l'icône */
    color: var(--rougepl); /* Optionnel : changer la couleur de l'icône */
  }

  /* .icones-choisir .icon-huile{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-abrasif{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-visserie{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-joint{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-adhesif{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-cable{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-flexible{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-rouleau{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-siege{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-chaussure{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-gant{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-lunette{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-flacon{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
  .icones-choisir .icon-gel{
    font-size: 3.45rem;
    padding-right: 2rem;
    margin-top: 2rem;
  } */

}