figure {
	border-radius: 0.5rem;
	display: flex;
	overflow: hidden;
	align-content: center;
}
img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}
.item-img-produit {
	/* box-shadow: var(--shadow); */
	display: flex;
	flex-direction: column;
	max-height: 17rem;
}
.item-img-produit img {
	object-fit: contain;
}
.img-Marque-ficheProduit {
	width: 50%; /* ou une taille spécifique plus grande */
	height: auto;
	image-rendering: -webkit-optimize-contrast; /* Préserve la qualité en agrandissant */
}

.img-Produit-ficheProduit {
	width: 100%;
	height: 26.1rem;
}

/* .img-bulle-faq {
	width: 50rem;
	height: 32.6rem;
	margin-right: 3.6rem;
	margin-top: 10rem;
	border-radius: 5px;
} */
.img-en-vedette {
	width: 100%;
	display: block;
}
.img-featured {
	height: 100%;
}
.img-sitesPartenaires {
	width: 20.9rem;
	height: 6.9rem;
	margin-right: 2rem;
}
.img-nosMarques {
	width: 29.7rem;
	height: 17.5rem;
	margin-right: 2rem;
	margin-left: 1rem;
}
.img-tecdoc {
	margin-top: 5rem;
	margin-bottom: 6rem;
	margin-left: 20rem;
	margin-right: 4rem;
	width: 30.2rem;
	height: 8.9rem;
}
.overlay-gris {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 2, 2, 0.5); /* Overlay bleu semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loading-container {
  text-align: center;
}
.loading-gif {
  width: 100px;
  height: 100px;
}