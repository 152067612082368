.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin: 1rem;
    border: .1rem solid transparent;
    border-radius: 0.25rem;
    font-size: 2rem;
    justify-content: center;
  }
.alert-danger {
    color: var(--alertdangertypo);
    background-color: var(--alertdangerfond);
    border-color: var(--alertdangerborder);
  }
  .alert-primary {
    color: var(--alertprimarytypo);
    background-color: var(--alertprimaryfond);
    border-color: var(--alertprimaryborder);
  }
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  .swal2-close{
    display: none !important;
  }

  div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line][class$=left]{
    left: -3.9375em !important;
    transform: rotate(45deg);
  }
  .swal2-confirm{
    background-color: var(--bleufond);
    width: 10rem;
    font-size: 1.5rem;
  }

  .rouge{
    color: var(--rougepl);
  }