/**********/
/* Colonnes */
/**********/

.col-10 {
	width: 10%;
}
.col-15 {
	width: 15%;
}
.col-20 {
	width: 20%;
}
.col-30 {
	width: 30%;
}
.col-33 {
	width: 33.33%;
}
.col-34 {
	width: 34%;
}
.col-35 {
	width: 35%;
}
.col-36 {
	width: 36%;
}
.col-37 {
	width: 37%;
}
.col-40 {
	width: 40%;
}
.col-44 {
	width: 44.5%;
}
.col-45 {
	width: 45%;
}
.col-50 {
	width: 50%;
}
.col-51 {
	width: 51%;
}
.col-53 {
	width: 53%;
}
.col-60 {
	width: 60%;
}
.col-70 {
	width: 70%;
}
.col-73 {
	width: 73%;
}
.col-75 {
	width: 75%;
}
.col-80 {
	width: 80%;
}
.col-90 {
	width: 90%;
}
.col-100 {
	width: 100%;
}
/*MISE EN PAGE*/
.d-flex {
	display: flex;
}
.d-block {
	display: block;
}
.d-none {
	display: none;
}
.align-items-center {
	align-items: center;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-between {
	justify-content: space-between;
}
.flex-column {
	flex-direction: column;
}
.flex-row {
	flex-direction: row;
}
.flex-column-reverse {
	flex-direction: column-reverse;
}
.flex-row-reverse {
	flex-direction: row-reverse;
}
.row {
	display: flex;
	flex-direction: row;
	margin-left: -1.5rem;
	margin-right: -1.5rem;
}
.column {
	display: flex;
	flex-direction: column;
}
.container,
.xl-container {
	margin: 0 auto;
	padding: 0 2rem;
	width: 100%;
}
@media screen and (max-width: 576px) {
	.container {
		width: 100%;
	}
}
@media screen and (min-width: 768px) {
	[class*="col-md-"] {
		padding: 0 2rem;
	}
	.col-md-10 {
		width: 10%;
	}
	.col-md-13 {
		width: 13%;
	}
	.col-md-15 {
		width: 15%;
	}
	.col-md-20 {
		width: 20%;
	}
	.col-md-25 {
		width: 25%;
	}
	.col-md-30 {
		width: 30%;
	}
	.col-md-40 {
		width: 40%;
	}
	.col-md-50 {
		width: 50%;
	}
	.col-md-60 {
		width: 60%;
	}
	.col-md-70 {
		width: 70%;
	}
	.col-md-80 {
		width: 80%;
	}
	.col-md-83 {
		width: 83%;
	}
	.col-md-90 {
		width: 90%;
	}
	.col-md-100 {
		padding: 0;
		width: 100%;
	}
	.md-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-left: -2rem;
		margin-right: -2rem;
	}
	.md-row-reverse {
		flex-direction: row-reverse;
	}
	.md-column {
		flex-direction: column;
	}
	.md-column-reverse {
		flex-direction: column-reverse;
	}
	.col-50 {
		width: calc(50% - 3rem);
	}
	.col-50:first-of-type {
		margin: unset;
	}
	.container {
		width: 98%;
	}
	.d-md-flex {
		display: flex;
	}
}
@media screen and (max-width: 899px) {
	/* .container{
		padding: 0 2rem;
	} */
}
@media screen and (min-width: 992px) {
	.container {
		width: 98%;
	}
	.lg-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-left: -2rem;
		margin-right: -2rem;
	}
}
@media screen and (min-width: 1200px) {
	[class*="col-l-"] {
		padding: 0 2rem;
	}
	.col-l-10 {
		width: 10%;
	}
	.col-l-20 {
		width: 20%;
	}
	.col-l-30 {
		width: 30%;
	}
	.col-l-40 {
		width: 40%;
	}
	.col-l-50 {
		width: 50%;
	}
	.col-l-60 {
		width: 60%;
	}
	.col-l-70 {
		width: 70%;
	}
	.col-l-80 {
		width: 80%;
	}
	.col-l-90 {
		width: 90%;
	}
	.col-l-100 {
		width: 100%;
	}
	.l-row {
		flex-direction: row;
	}
	.l-row-reverse {
		flex-direction: row-reverse;
	}
	.l-column {
		flex-direction: column;
	}
	.l-column-reverse {
		flex-direction: column-reverse;
	}
	.container {
		width: 114rem;
	}
}
@media screen and (min-width: 1400px) {
	.col-xl-10 {
		width: 10%;
	}
	.col-xl-20 {
		width: 20%;
	}
	.col-xl-30 {
		width: 30%;
	}
	.col-xl-40 {
		width: 40%;
	}
	.col-xl-50 {
		width: 50%;
	}
	.col-xl-60 {
		width: 60%;
	}
	.col-xl-70 {
		width: 70%;
	}
	.col-xl-80 {
		width: 80%;
	}
	.col-xl-90 {
		width: 90%;
	}
	.col-xl-100 {
		width: 100%;
	}
	.xl-row {
		flex-direction: row;
	}
	.xl-row-reverse {
		flex-direction: row-reverse;
	}
	.xl-column {
		flex-direction: column;
	}
	.xl-column-reverse {
		flex-direction: column-reverse;
	}
	.container {
		width: 135rem;
	}
}
@media screen and (min-width: 1800px) {
	.col-xxl-10 {
		width: 10%;
	}
	.col-xxl-20 {
		width: 20%;
	}
	.col-xxl-30 {
		width: 30%;
	}
	.col-xxl-40 {
		width: 40%;
	}
	.col-xxl-50 {
		width: 50%;
	}
	.col-xxl-60 {
		width: 60%;
	}
	.col-xxl-70 {
		width: 70%;
	}
	.col-xxl-80 {
		width: 80%;
	}
	.col-xxl-90 {
		width: 90%;
	}
	.col-xxl-100 {
		width: 100%;
	}
	.xxl-row {
		flex-direction: row;
	}
	.xxl-row-reverse {
		flex-direction: row-reverse;
	}
	.xxl-column {
		flex-direction: column;
	}
	.xxl-column-reverse {
		flex-direction: column-reverse;
	}
	.container {
		width: 87.5%;
	}
}
