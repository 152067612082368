.sticky.connexion::before {
	background-color: var(--bleufond);
	content: "";
	display: block;
	height: 2.5rem;
	width: 100%;
	height: 1rem;
}
.navigation-principale {
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.nav-principale {
	display: flex;
	justify-content: end;
	height: inherit;
}
.nav-principale ul {
	align-items: center;
	display: flex;
	height: inherit;
	list-style: none;
	justify-content: space-between;
	padding: 0;
	width: 100%;
}
.nav-principale li a {
	text-decoration: none;
	color: var(--blanc);
	display: flex;
	align-items: center;
	font-size: 1.5rem;
	padding-right: 4rem;
}
.navigation-principale li:last-of-type a {
	padding: 0;
}
.nav-principale li a i {
	margin-right: 1rem;
}
.nav-principale li a i:before {
	font-size: 2rem;
}
.navigation-secondaire {
	align-items: center;
	background-color: var(--bleugadifonce);
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	position: relative;
}
.navigation-secondaire ul {
	display: flex;
	flex-direction: column;
	height: 5.8rem;
	padding: 0rem 1.5rem;
	list-style: none;
	width: 80%;
}
.navigation-secondaire li a,
.navigation-secondaire li a i {
	color: var(--blanc);
}
.navigation-secondaire li a {
	align-items: center;
	color: var(--blanc);
	display: flex;
	font-family: var(--bold);
	font-size: 1.6rem;
	height: 100%;
}
.navigation-secondaire li a span {
	font-size: inherit;
}
.navigation-secondaire li a i {
	font-size: 2.4rem;
	margin-right: 1rem;
	cursor: pointer;
}
.banner-pl {
	display: none;
}
.logo-header {
	display: flex;
	width: 100%;
}
.logo-header img {
	height: inherit;
	object-fit: contain;
}
/* .nav-grouped {
	display: none;
} */
.menu-toggle-btn,
.mon-panier {
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: var(--blanc);
	display: block;
	font-size: 5rem;
	position: relative;
}
.menu-toggle-btn {
	order: 3;
}
.menu-toggle-btn i,
.menu-toggle-btn i:before {
	margin: 0;
	font-size: inherit;
}
.menu-toggle-btn-close {
	display: flex;
	color: var(--bleutypofonce);
	justify-content: end;
	width: 100%;
}
.link-metier.current,
.link-metier.current i::before {
	color: var(--bleutypo);
}
@media screen and (max-width: 576px) {
	.logo-header {
		justify-content: center;
	}
	.logo-header img {
		width: 80%;
	}
}
@media screen and (min-width: 768px) {
	.navigation-secondaire .item-nav {
		text-decoration: none;
		color: var(--blanc);
		display: flex;
	}
	li.item-nav-princ {
		display: flex;
		align-items: center;
		/* font-size: 1.5rem; */
		padding-right: 4rem;
		color: var(--blanc);
	}
	li.item-nav-princ:last-child {
		padding-right: 0;
	}
	li.item-nav-princ span {
		padding-left: 2rem;
		cursor: pointer;
	}
	.item-nav-princ i::before {
		font-size: 2rem;
		cursor: pointer;
	}
	.logo-header {
		width: 35%;
	}
}
@media screen and (max-width: 899px) {
	.nav-principale li span {
		padding: 0 3.6rem 3.6rem 3.5rem;
		font-size: 1.1669rem;
	}
	.nav-principale li {
		padding: 0 0 3.6rem 0;
	}
	.nav-principale i::before {
		font-size: 2.325rem;
	}
}
@media (max-width: 1024px) {
	.navigation-smart {
		align-items: center;
		background-color: var(--blanc);
		bottom: 0;
		display: flex;
		justify-content: space-between;
		height: 5rem;
		padding: 0 1.5rem;
		position: fixed;
		width: 100%;
		z-index: 1;
	}
	.navigation-smart::before {
		background-color: var(--bleufond);
		content: "";
		display: block;
		height: 0.5rem;
		left: 0;
		position: absolute;
		top: -0.5rem;
		width: 100%;
	}
	.navigation-smart button {
		margin: unset;
		padding: unset;
	}
	.navigation-smart p {
		color: var(--bleufond);
		font-family: var(--bleufond);
	}
	.navigation-smart p i {
		display: inline-block;
		margin-right: 1rem;
	}
	.navigation-smart p i::before {
		font-size: 2.58rem;
	}

	.menu-toggle-btn,
	.mon-panier {
		background-color: transparent;
		color: var(--bleufond);
		font-size: 2.5rem;
		padding: 1rem;
	}
	.mon-panier i {
		margin: 0;
	}
	.nav-grouped {
		left: 0;
		position: absolute;
		top: 0;
		transform: translateX(100%);
		transition: all 0.5s ease-in-out;
		width: 100%;
		z-index: 4;
	}
	.nav-grouped.open {
		transform: translateX(0%);
	}
	.container-menu-smart::before {
		background-color: var(--bleugadifonce75);
		content: "";
		display: block;
		height: 100vh;
		width: 6rem;
	}
	.container-menu-smart {
		display: flex;
		height: 100vh;
		width: 100%;
		z-index: 2;
	}
	.menu-smart {
		background-color: var(--bleugadifonce);
		box-shadow: -0.2rem 0px 1.2rem 0.6rem var(--noirshadow);
		height: 100vh;
		width: 100%;
		padding: 2rem;
	}

	.text-menu {
		direction: ltr;
		display: block;
		text-indent: -999em;
		height: 0;
		width: 0;
	}
	.nav-principale li a {
		font-size: 1.2rem;
		color: var(--grisgadi);
	}
	.nav-principale li a i::before {
		color: inherit;
	}
	.nav-principale,
	.navigation-secondaire {
		display: block;
		margin: unset;
	}
	.nav-principale ul,
	.navigation-secondaire ul {
		align-items: unset;
		display: flex;
		flex-direction: column;
		height: unset;
		padding: 0 2rem;
		transform: unset;
		width: 100%;
		color: var(--grisgadi);
		font-style: italic;
	}
	.nav-principale ul li a,
	.navigation-secondaire ul li a {
		padding-bottom: 3rem;
	}
	.nav-principale ul li a i,
	.navigation-secondaire ul li a i {
		margin-right: 2rem;
	}
	.nav-principale li a {
		padding-left: 0.5rem;
		padding-right: 0;
	}
	.navigation-secondaire li a {
		font-size: 1.4rem;
	}
	.navigation-secondaire li a i.icon-piecesauto {
		font-size: 2rem;
	}
}
@media screen and (min-width: 992px) {
	.sticky::before {
		height: 4rem;
	}
	.navigation-secondaire ul {
		flex-direction: row;
		height: 8rem;
		justify-content: space-evenly;
		width: 100%;
	}
	.navigation-secondaire li {
		display: flex;
		align-items: center;
	}
	.navigation-secondaire li a span {
		display: block;
		height: 1.5rem;
		cursor: pointer;
	}
	.navigation-secondaire li a:hover,
	.navigation-secondaire li a.current {
		color: var(--bleutypo);
	}
	.navigation-secondaire li a:hover i,
	.navigation-secondaire li a.current i {
		color: inherit;
	}
	.menu-mobile {
		display: none;
	}
	.logo-header {
		width: 22rem;
	}
}
@media screen and (min-width: 1200px) {
	.navigation-secondaire ul {
		flex-direction: row;
	}
	.mobile-logo {
		display: none;
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
