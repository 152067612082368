.container-col-listeArticle {
	display: flex;
	gap: 4rem;
	padding-top: 2rem;
}
.col-filtre {
	width: 30%;
}
.col-liste-Article {
	width: 70%;
}
.col-liste-Article > div:first-child {
	margin-top: 0;
}
.btn-ajoutPanier-listeArticle {
	margin-top: 0.9375rem;
	background-color: var(--bleufond);
	color: var(--blanc);
	border-radius: 3.125rem;
	font-family: var(--regular);
	width: 100%;
}
.container_img-listeArticle i::before {
	font-size: 4rem;
	padding: 0 3rem;
}

.container_img-listeArticle .compatible {
	font-size: 1.3rem;
}
.check-compatible {
	border-radius: 1rem;
	background-color: var(--vert00B100);
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	/* width: 11%; */
}
.entete-info-tableau .check-compatible{
	border-radius: 1rem;
	background-color: var(--vert00B100);
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	width: 11%;
}
.check-compatible p {
	font-size: 1rem;
	margin-top: 0.5rem;
	text-align: center;
}
.check-compatible i {
	margin-right: 0.5rem;
}
.check-compatible p,
.check-compatible i {
	color: var(--blanc);
}
.container_liste-article {
	box-shadow: 0px 0px 6px #00000029;
	border-radius: 5px;
	gap: 4rem;
	padding: 3rem 3rem 2rem 3rem;
	margin-top: 2rem;
}

.masquer-listeArticle .icon-masquer-prix {
	font-size: 2.7rem;
	width: 3.7rem;
	height: 3rem;
	cursor: pointer;
	transition: transform 0.5s;
}

.masquer-listeArticle .icon-afficher-prix {
	font-size: clamp(1.9rem, 2rem, 2.1rem);
	width: 3.7rem;
	height: 3rem;
	margin-top: 1rem;
	cursor: pointer;
	transition: transform 0.5s;
	margin-bottom: 2rem;
}

.plus-info-listeArticle {
	color: var(--bleugadisat);
	font-size: clamp(1.5rem, 1.5rem, 1.6rem);
	font-style: italic;
	margin-top: 1rem;
	cursor: pointer;
}
.dropdown-menu-categories .row {
	flex-wrap: wrap;
}
.dropdown-menu-categories .col {
	flex: 1;
	min-width: 20rem;
}
.quantite-listeArticle {
	border-radius: 5rem;
	width: 100%;
}
.quantite-moins:disabled {
	background-color: #e0e0e0;
	cursor: not-allowed;
}
.quantite-moins {
	border-radius: 5rem 0rem 0rem 5rem;
}
.input-centre {
	border-top: 1px solid var(--grisprez);
	border-radius: unset;
	border-bottom: 1px solid var(--grisprez);
}
.quantite-plus {
	border-radius: 0rem 5rem 5rem 0rem;
}
.quantite-moins,
.quantite-plus {
	border: 1px solid var(--grisprez);
}
.quantite-plus {
	background-color: var(--blanc);
}
.quantite-conso.quantite-listeArticle .quantite-plus,
.button-quantite.quantite-plus {
	border-radius: unset;
}
.quantite-listeArticle .button-quantite.quantite-plus {
	border-radius: 0rem 5rem 5rem 0rem;
}
.panel-articleListe {
	margin-bottom: 2rem;
}
.container_img-listeArticle {
	width: 20%;
}
.panel-liste-article {
	box-shadow: 0px 0px 6px #00000029;
	border-radius: 5px;
	width: 20%;
	/* max-height: 17.2rem; */
	/* margin: 3rem; */
	display: flex;
	flex-direction: column; /* Empiler les images verticalement */
	justify-content: space-between; /* Assure un espacement régulier entre les images */
	align-items: center; /* Centrer les images horizontalement */
	overflow: hidden;
}

.panel-liste-article figure {
	margin: 0;
	width: 100%;
	flex-grow: 1; /* Permet aux figures de prendre plus de place */
	display: flex;
	justify-content: center;
	align-items: center;
}

.img-Marque-ficheProduit {
	width: 78%; /*Augmenter la taille des images en largeur */
	height: auto; /* Maintenir le ratio d'aspect */
	object-fit: contain; /* Ajuster l'image à l'espace disponible tout en maintenant ses proportions */
	display: block;
}

.prix-panier-listeArticle {
	margin-top: 3rem;
	margin-left: 1rem;
}

.info-vehicule-produit {
	flex-direction: column;
	width: 80%;
}

.btn-ajoutPanier-listeArticle .container_img-listeArticle {
	margin: 2rem 3rem 3rem 3rem;
}

.stock-liste-article {
	margin-top: 2rem;
}

.criteria-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	margin-top: 1rem;
	/* background-color: var(--blanc); */
	font-style: italic;
	width: 100%;
}

.criteria-item {
	display: flex;
	padding: 0.5rem 0;
	align-items: center;
	font-size: clamp(1.5rem, 1.5rem, 1.6rem);
	margin-top: 0.2rem;
}
.criteria-item ul {
	list-style-type: none;
}

.criteria-item:nth-child(even) {
	background-color: white; /* Blanc */
}

.bg-gray {
	background-color: var(--grisclair);
}

.bg-white {
	background-color: var(--blanc);
}

.panel-essieu-avant-listeArticle {
	box-shadow: 0px 0px 6px #00000029;
	border: 2px solid #e0e0e0;
	border-radius: 5px;
	width: 611px;
	height: 162px;
	margin-top: 2rem;
	/* margin-left: 52rem; */
	margin-right: 2rem;
}

.essieu-avant {
	font-size: 20px;
	font-family: var(--regular);
	text-transform: uppercase;
	color: var(--bleugadisat);
}

.panel-essieu-arriere-listeArticle {
	box-shadow: 0px 0px 6px #00000029;
	border: 2px solid #e0e0e0;
	border-radius: 5px;
	width: 611px;
	height: 162px;
	margin-top: 2rem;
}

.essieu-arriere {
	font-size: 20px;
	font-family: var(--regular);
	text-transform: uppercase;
	color: var(--bleugadisat);
}
.panel-marque-listeArticle {
	box-shadow: 0px 0px 6px #00000029;
	border: 0.125rem solid #e0e0e0;
	border-radius: 5px;
	height: 8.25rem;
	/* margin-top: 2rem; activer lorsque les essieux seront remis */
	display: flex;
	justify-content: space-between; /* Assure la répartition des icônes de flèche et des images */
	align-items: center;
	margin-bottom: 2rem;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
  scroll-snap-type: x mandatory; /* Optional: snap to each item on scroll */
}

.img-marque,
.img-liste-marque {
	cursor: pointer;
	max-width: 85%;
}


.icon-fleche-droite-marque {
	margin-right: 3rem;
	width: 18px;
	height: 30px;
	margin-top: 1rem;
	font-size: 23px;
	opacity: 1;
	color: var(--bleufond);
}

.icon-fleche-gauche-marque {
	margin-left: 3rem;
	width: 18px;
	height: 30px;
	margin-top: 1rem;
	font-size: 23px;
	opacity: 1;
	color: var(--bleufond);
}
.raccourci-container h4 {
	align-items: center;
	background-color: var(--grisclair);
	display: flex;
	color: var(--bleugadisat);
	font-size: 2.25rem;
	height: 4.5rem;
	text-transform: uppercase;
	padding: 0 2rem;
}
.panel-meme-famille {
	border: 0px;
	border: 2px solid var(--grisclair);
	opacity: 1;
	height: auto;
	padding: 4rem;
}
ul.panel-meme-famille {
	list-style: none;
}
.panel-meme-famille li {
	color: var(--bleutypofonce);
	font-size: 2rem;
}
.panel-meme-famille li label {
	align-items: center;
	display: flex;
	padding: 1rem 0;
}
.panel-meme-famille li:first-of-type label {
	padding-top: 0;
}

.panel-meme-famille li span {
	display: inline-block;
}

/* Réduire la taille des checkboxes */
.small-checkbox,
.small-checkbox-cocher {
	width: 2.5rem;
	height: 2.5rem;
	margin-right: 1.1rem;
}
.label-cocher {
	align-items: center;
	display: flex;
	font-size: 2.2rem;
	padding-top: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

.reinitialiser {
	align-items: center;
	display: flex;
	font-size: clamp(2rem, 2.1rem, 2.2rem);
	padding-top: 0.5rem;
	padding-left: 1rem;
}
.cross-icon {
	cursor: pointer; /* Change le curseur en pointeur pour indiquer que c'est cliquable */
	color: var(--bleufond); /* Couleur de la croix, ajustez comme souhaité */
	float: right; /* Aligne la croix à droite */
	display: flex;
	align-items: center;
	padding: 0rem 1rem;
}
.cross-icon:hover {
	color: var(--bleugadisat); /* Changement de couleur lors du survol */
}
.update {
	cursor: pointer; /* Change le curseur en pointeur pour indiquer que c'est cliquable */
	color: var(--bleufond); /* Couleur de la croix, ajustez comme souhaité */
	font-size: clamp(
		1.5rem,
		1.6rem,
		1.7rem
	); /* Taille de la croix, ajustez selon vos besoins */
	margin-top: 2.8rem;
}

.update:hover {
	color: var(--bleugadisat); /* Changement de couleur lors du survol */
}

.raccourci-cocher,
.selected-brand {
	height: auto;
	border: 2px solid var(--bleutypofonce);
	border-radius: 3px;
	width: auto;
	display: inherit;
	align-items: center;
	padding: 0.5rem;
}

.nom-marque{

}

.raccourci-container-cocher:first-child {
	margin-left: 20rem;
}
.classer-resultat {
	padding: 2rem 0;
	font-family: var(--regular);
	gap: 1rem;
}
.classer-resultat p {
	color: var(--grisgadi);
	text-align: center;
}
.filtres-buttons {
	display: flex;
}
.filtres-buttons button {
	background-color: transparent;
	color: var(--grisgadi);
	font-size: 1.2rem;
}
.filtres-buttons button i {
	display: none;
	font-size: 1.1rem;
	margin-right: 0.5rem;
}
.filtres-buttons button i.actif {
	display: inline-block;
}
.filtres-buttons button.actif {
	color: var(--bleugadisat);
}

.panel-meme-famile > ul {
	padding-left: 1.1rem;
}
.prix-organiser {
	margin-left: 1rem;
	color: var(--grisgadi);
	font-size: 1.5rem;
}

.vertical-separator {
	width: 2px; /* Épaisseur du trait */
	height: 70px; /* Hauteur du trait */
	background-color: var(--grisclair); /* Couleur grise du trait */
	margin: 3.5rem 1.5rem; /* Espacement horizontal entre les marques */
}
.consigne-items {
	display: flex;
}
.consigne {
	color: var(--bleutypofonce);
}
.prix .consigne span,
.prix .consigne sup {
	font-family: var(--regular);
	color: inherit;
}
.prix .consigne span {
	font-size: 1.5rem;
}
.prix .consigne sup {
	font-size: 0.9rem;
	text-transform: uppercase;
}
.consigne i {
	display: inline-block;
	margin-right: 0.25rem;
}
.container_listeArticle-pas-de-filtre {
	width: 35rem;
}

.supprimer-filtre {
	color: var(--bleufond);
	font-size: clamp(1.5rem, 1.8rem, 2rem);
}

/************************************************************/
/* CCS POUR  QUAND ON APPUIE SUR LE BOUTON AJOUTER AU PANIER*/
/************************************************************/

/* Griser la page */
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--grisgadi);
	opacity: 0.5;
	z-index: 10;
	display: none;
}

/* Panel blanc centré en bas */
.notification {
	position: fixed;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	padding: 20px;
	background-color: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	text-align: center;
	display: none;
	z-index: 20;
}

/* Montrer le panel et l'overlay */
.visible {
	display: block;
}

/* Bouton pour fermer la notification */
.close-btn {
	position: relative;
	left: 55%;
	font-size: clamp(3rem, 4rem, 5rem);
	cursor: pointer;
	color: var(--bleutypofonce);
}

/* Message du panel */
.notification-message {
	font-size: clamp(3rem, 3rem, 3.9rem);
	margin: 10px 0;
	color: var(--bleugadisat);
	justify-content: center;
	align-items: center;
}

.notification-message i::before {
	font-size: 4.2rem;
	margin-right: 1.5rem;
}

.list-items-ajout-panier {
	margin-left: 12rem;
	margin-right: 12rem;
}

.col-item-ajout-produit {
	justify-content: space-between;
}

.btn-ajoutCde-panier {
	align-items: center;
	/* width: 100%; */
	justify-content: end;
}

.btn-voirPanier-listeArticle {
	margin-top: 1.5rem;
	background-color: var(--blanc);
	color: var(--bleufond);
	border: 2px solid var(--bleufond);
	border-radius: 5px;
	font-family: var(--regular);
	width: 310px;
}
.btn-passerCde-listeArticle {
	margin-top: 1.5rem;
	background-color: var(--bleufond);
	color: var(--blanc);
	border-radius: 5px;
	font-family: var(--regular);
	width: 404px;
	margin-left: 2rem;
}

.title-ref-item-produit-cde {
	padding-left: 3rem;
	padding-top: 1.5rem;
}

.title-ref-item-produit-cde * {
	text-align: left;
}

.item-mon-panier-cde {
	box-shadow: var(--shadow);
	margin-top: 4rem;
	border-radius: 5px;
	padding: 4rem 6rem 4rem 4rem;
	justify-content: space-between;
}

.description2 {
	justify-content: flex-start;
	display: flex;
}

.reference-cde {
	margin-bottom: 1rem;
	margin-top: revert;
	justify-content: flex-start;
	display: flex;
}

.ajoute-text {
	font-size: clamp(1.5rem, 1.8rem, 2.5rem);
	font-family: var(--regular);
	margin-top: 1rem;
	justify-content: center;
	align-items: center;
}

.ajoute-text .icon-valide-plein {
	font-size: clamp(1.9rem, 2.5rem, 3rem);
	margin-right: 1rem;
}

.container_liste-article .row {
	gap: 1rem;
}
.global-info {
	gap: 5rem;
}

.update-button{
	align-items: center;
	padding: 0 1rem;
}

.update-button i::before{
	color: var(--bleufond);
}

.resultats-cross-oe{
	border: 1px solid var(--grisgadi);
}
.filtres-buttons button.actif {
	background-color: var(--vert00B100);
}

@media screen and (max-width: 576px) {
	.col-liste-Article {
		width: 100%;
	}
	.brand-vignette {
		margin: 0.2rem;
	}
	.info-vehicule-produit {
		width: 100%;
	}
	.container_liste-article {
		display: flex;
		flex-direction: column;
	}
	.prix-panier-listeArticle .prix {
		width: 100%;
	}
	.prix-panier-listeArticle .prix .ht {
		font-size: unset;
	}
	.prix-panier-listeArticle .prix span {
		font-size: 3.3rem;
	}
	.prix-panier-listeArticle .prix sup {
		font-size: 2.5rem;
	}
	.prix-panier-listeArticle .prix .consigne span {
		font-size: 1.5rem;
	}
	.prix-panier-listeArticle .prix .consigne sup {
		font-size: 0.95rem;
	}
	.panier-listeArticle {
		width: 80%;
	}
	.btn-ajoutPanier-listeArticle i::before{
		font-size: 2.5rem;
	}
	.info-vehicule-produit .check-compatible{
		width: 40%;
		margin: 1rem 0;
		padding: 0.2rem 1rem;
	}
	.consigne-items-liste{
		margin-top: 1rem;
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
	.info-tableau-ref-stock {
		width: 100%;
	}
	.prix-panier-listeArticle {
		margin-left: 0;
		width: 40%;
	}
	.check-compatible p {
		font-size: 1.2rem;
	}
}
@media screen and (max-width: 1024px) {
	.container-col-listeArticle {
		display: block;
	}
	.container_liste-article {
		box-shadow: none;
		gap: 0;
		padding: 0;
	}
	/* .panel-liste-article {
		height: 25%;
		max-width: 30%;
	} */
	/* .container_img-listeArticle{
		width: 100%;
	} */
	.nom-produit {
		margin: 0;
	}
	.stock-liste-article {
		padding: 1rem 0 0 0;
		margin: 0;
	}
	.global-info {
		flex-direction: column;
		gap: 2rem;
	}
	.prix-panier-listeArticle {
		margin: 0;
		display: flex;
		gap: 1.5rem;
		justify-content: space-between;
	}
	.nom-produit h3 {
		font-size: 1.33rem;
		margin-bottom: 1rem;
	}
	.nom-produit p {
		font-size: 1.17rem;
	}
	.nom-produit .produit {
		font-size: 1.33rem;
	}
	.container_liste-article .row {
		gap: 1rem;
		padding: unset;
		padding-top: 2rem;
		margin: unset;
		width: 100%;
	}
	.container_liste-article:first-child .row {
		padding-top: 0;
	}
	.quantite-listeArticle ul {
		width: 7rem;
	}
	.stock-liste-article .vert {
		font-size: 1.33rem;
	}
	.notification-message{
		padding-bottom: 1rem;
	}
	.notification-message,
	span.qte-ajoute{
		font-size: 1.5rem;
		color: var(--vert00B100);
		margin: 0;
	}

	.after-article {
		border-top: 4px solid var(--grisprez);
		margin-top: 20px;
		padding-top: 20px;
	}
	.plus-info-listeArticle {
    font-size: 1.2rem;
		margin-bottom: 1rem;
	}

	.list-items-ajout-panier {
		margin: 0;
	}
	.btn-voirPanier-listeArticle,
	.btn-passerCde-listeArticle{
		margin-left: 0rem;
		width: 100%;
		font-size: 1.3rem;
	}
	.btn-ajoutCde-panier{
		flex-direction: column-reverse;
	}

	.title-ref-item-produit-cde h2.description1,
	p.reference-cde{
		font-size: 1.2rem;
	}
	.panel-listeArticle-cde{
		padding-top: 1rem;
	}
	.item-mon-panier-cde {
		padding: 0;
		box-shadow: none;
		flex-direction: column;
		margin: 0;
		padding-top: 1rem;
	}
	.notification-message i::before{
		font-size: 4rem;
		margin-bottom: 1rem;
	}
	.close-btn{
		color: var(--grisgadi);
		left: 50%;
	}
	.title-ref-item-produit-cde{
		padding-top: 0;
		padding-left: 1rem;
	}
	.panel-marque-listeArticle{
		box-shadow: none;
		border: 0;
		justify-content: space-evenly;
	}
	.brand-vignette {
		flex: 0 0 30%; /* Each item takes up 30% of the container width */
		max-width: 30%; /* Prevents it from growing too large */
		height: 80%; /* Set a fixed height for uniform borders */
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border: 2px solid var(--grisgadi); /* Border */
		scroll-snap-align: start; /* Snap each item to the start */
		margin: 0 5px; /* Space between items */
	}
	
	.img-liste-marque {
		width: 70%; /* Set width according to your design */
		height: auto;
	}
	.filtrer-toggle-btn, 
	.organiser-toggle-btn {
		background-color: var(--bleufond);
		font-size: 2.5rem;
		padding: 1rem;
		width: 50%;
		height: 4rem;
	}
	.icon-parametres::before {
		font-size: 1.73rem;
		height: 2.8rem;
		width: 1.1rem;
		cursor: pointer;
		color: var(--blanc);
		display: flex;
    align-items: center;
	}
	.menu-smart.filtrer-article,
	.menu-smart.organiser-article {
		background-color: var(--blanc);
		box-shadow: -0.2rem 0px 1.2rem 0.6rem var(--noirshadow);
		height: 100vh;
		width: 100%;
		padding: 0;
		overflow-y: auto;
	}
	.text-menu-filtre,
	.text-menu-organiser{
		font-size: 1.17rem;
		color: var(--blanc);
		font-family: var(--regular);
	}
	.icon-text-filtre{
		justify-content: space-between;
		align-items: center;
		padding: 3rem;
		width: 100%;
	}
	.filtre-cocher {
		padding: 1rem 1rem;
		max-height: 100vh; /* Limite la hauteur maximale pour ne pas dépasser l'écran */
		overflow-y: auto; /* Ajoute un scroll vertical si le contenu dépasse */
		overflow-x: hidden; /* Évite le défilement horizontal */
		position: relative; /* Permet le contrôle de l'overflow */
	}	
	.panel-filtre,
	.panel-organiser {
		background-color: var(--grisclair);
		padding: 1rem;
		position: relative;
	}	
	.panel-filtre::after,
	.panel-organiser::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0.416rem;
		background-color: var(--bleufond);
	}
	.titre-filtre,
	.titre-organiser {
		text-transform: uppercase;
		color: var(--bleugadisat);
		font-size: 1.17rem;
		letter-spacing: 0.029rem;
	}
	.panel-meme-famille {
    border: 0px;
    opacity: 1;
    height: auto;
    padding: 1rem;
	}
	.panel-meme-famille li span {
    font-size: 1.17rem;
	}
	.menu-toggle-btn-filtrer-article, 
	.menu-toggle-btn-organiser-article {
		background-color: var(--bleufond);
		color: var(--blanc);
		border-radius: 5px;
		width: 50%;
		height: 100%;
	}
	.organiser-cocher {
		padding: 1rem 1rem;
	}
	.icon-organiser::before {
		font-size: 1.48rem;
		height: 2.8rem;
		width: 1.1rem;
		cursor: pointer;
		color: var(--blanc);
		display: flex;
    align-items: center;
	}
	.raccourci-container-cocher:first-child{
		margin: 0;
	}
	.recherche-filtre-organiser{
		gap: 1rem;
		margin-bottom: 1rem;
	}
	.englobe-toutes-caseCocher{
		flex-wrap: wrap;
		gap: 1rem;
	}
	.filtres-buttons {
		transition: margin-top 0.3s;
	}
	.filtres-buttons.has-filters {
		margin-top: 20px; /* Ajustez cette valeur pour placer le bouton plus bas */
	}

}
@media screen and (min-width: 1200px) {
	.container_liste-article {
		flex-direction: column;
	}
}
@media screen and (min-width: 1400px) {
	.classer-resultat {
		justify-content: space-between;
		gap: unset;
	}
	.stock-liste-article {
		padding: 0;
	}
}
@media screen and (min-width: 1800px) {
}
