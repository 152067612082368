.divListeRechAuto {
	box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
	border-radius: 6px;
	/* border: 1px solid #000;  */
	color: #000;
	max-height: 200px;
	overflow-y: auto;
	width: 100%;
	position: absolute;
	z-index: 900;
	background-color: #fff;
	margin-top: 1rem;
}
.aListeRechAuto {
	border-radius: 0.2rem;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	padding: 1.3rem 0.8rem;
}
.ulListeRechAuto {
	padding: 1.6rem;
	list-style: none;
}
