.panel-mes-commandes {
  background-color: var(--blanc);
  box-shadow: var(--shadow);
  margin-top: 5rem;
  padding: 2rem 4rem 4rem 4rem;
}
.titre-mes-commandes {
  align-items: center;
  color: var(--bleugadisat);
  display: flex;
  font-size: 3rem;
  font-family: var(--bold);
}
.titre-mes-commandes i {
  color: var(--bleugadisat);
  font-size: 5rem;
  margin-right: 2rem;
}
.titre-mes-commandes span {
  display: block;
  height: 3rem;
}

.item-mes-commandes {
  box-shadow: var(--shadow);
  margin-top: 4rem;
  padding: 4rem;
  border-radius: 5px 5px 0 0;
  background-color: var(--grisclair);
  padding: 1.5rem 3rem 1.5rem 3rem;
}

.label-cde-en-cours{
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.btn-afficher-facture{
  display: block;
}

.label-cde{
  color: var(--bleugadisat);
  font-size: 2rem;
}
.img-info-commandes{
  padding: 2rem 0;
  /* margin: -5rem 0 3rem 0; */
}
/* .img-info-commandes:last-child{
  margin: -5rem 0 0 0;
} */

.panel-cde-en-cours{
  background-color: var(--blanc);
  /* box-shadow: var(--shadow); */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 -1px 0 rgba(0, 0, 0, 0); /* Ombre sur les côtés et en bas */
  /* border-radius: 0 0 5px 5px; */
  list-style: none;
  padding: 2rem 3rem 3rem 3rem;
  border-bottom: 1px solid var(--grisprez); /* Couleur et style de la bordure */
}
.panel-cde-en-cours:last-child {
  border-bottom: none; /* Supprime la bordure du dernier élément */
  border-radius: 0 0 5px 5px;
}

li h2.date-livraison-prevue{
  color: var(--bleufond);
  font-size: 2rem;
}
li h2.date-livre{
  color: var(--vert00B100);
  font-size: 2rem;
}

.commandes-passees{
  color: var(--bleugadisat);
  margin-top: 4rem;
}

p.commandes-passees{
  font-family: var(--bold);
}

p.commandes-passees span{
  font-family: var(--regular);
  margin-right: 2rem;
}
.dropdown-select-commande{
  cursor: pointer;
  height: 3.9rem;
  border: .1rem solid var(--grisgadi);
  background-color: var(--grisclair);
  border-radius: .5rem;
  color: var(--bleugadisat);
  font-size: 1.5rem;
  font-family: var(--bold);
  align-items: center;
  display: flex;
  padding: 0 2rem;
}
.dropdown-menu-commandes {
  display: none;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--grisgadi);
  border-top: none;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  text-indent: 10px;
}
.dropdown-menu-commandes.show{
  display: block;
  border-radius: 0 0 5px 5px;
  margin-left: -2.1rem;
  margin-top: 23rem;
}
.dropdown-select-commande i:before {
  font-size: clamp(1rem, 1.5rem, 3rem);
  padding: 0 0 0 2rem;
  color: var(--bleugadisat);
}

.dropdown-select-commande .fleche-bas.up::before{
  transform: rotate(0deg);
}

.col-item-img-commande{
  padding: 0 2rem 0 0;
}

.img-mes-commandes{
  width: 100%;
  height: 6.1rem;
}

.item-img-mes-commandes{
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  max-height: 12rem;
}

.ref-mes-commandes{
  margin-top: 1rem;
}
.effectuer-retour{
  margin-bottom: 1rem;
}
.effectuer-retour,
.afficher-facture{
  background-color: var(--blanc);
  border: 2px solid var(--grisprez);
  width: 17.5rem;
  height: 3.8rem;
  border-radius: 10px;
}
.effectuer-retour:hover,
.afficher-facture:hover{
  background-color: var(--grisprez);
}
span.retour,
span.facture{
  color: var(--bleugadisat);
  font-size: 1.5rem;
  font-family: var(--regular);
}

li h2.date-livre-cde-passee{
  color: var(--vert00B100);
  font-size: 2rem;
  margin-top: -5rem;
}

.recherche-commande {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 4rem 4rem 4rem;
}
h3.recherche-commande-text {
  font-size: 2.7rem;
  padding-bottom: 3rem;
}
p.recherche-commande-text{
  font-size: 2.1rem;
  padding-bottom: 1rem;
}
input.numero-commande{
  border: 1px solid var(--grisprez);
  flex-grow: 1; /* Utilise tout l'espace disponible */
  margin-bottom: 3rem;
  width: 100%;
}
input.numero-commande::placeholder{
  color: var(--grisgadi);
  font-style: italic;
}

.combien-commande-passees{
  align-items: end;
}

.numero-commandes{
  justify-content: space-between;
  align-items: end;
}
.numero-commandes i::before{
  color: var(--grisgadi);
  font-size: 2.11rem;
}

.input-group-commande {
  display: flex;
  align-items: center;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
  margin: 0;
  margin-bottom: 1rem;
}

.input-group-commande span {
  padding: 1.6rem 2rem;
  background-color: var(--grisclair);
  border: 1px solid var(--grisprez);
  border-radius: 5px 0 0 5px;
  border-right: none;
}

.input-group-commande input.periode-achat {
  flex: 1;
  border: none;
  padding: 0 2rem;
  border: 1px solid var(--grisprez);
  border-radius: 0 5px 5px 0;
}
.input-group-commande input.periode-achat.libelle-bleu{
  color: var(--bleugadisat);
}

.input-group-commande input.periode-achat::placeholder{
  color: var(--grisgadi);
  font-style: italic;
  font-size: 1.5rem;
}

.rechercher-commande-btn {
  align-items: center;
  background-color: var(--bleugadisat);
  border: none;
  border-radius: 5px;
  color: var(--blanc);
  cursor: pointer;
  display: flex;
  height: 5.5rem;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
}

.list-items-mes-commandes{
  list-style: none;
}

@media screen and (max-width: 1024px){
  .selection-produits-gris.selection-produits-blanc{
    background-color: var(--blanc);
  }
  .panel-mes-commandes{
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
  .titre-mes-commandes i {
    color: var(--bleugadisat);
    font-size: 3.33rem;
    margin-right: 2rem;
  }
  .recherche-filtre-commandes{
    padding: 2rem 0;
  }
  .input-filtre-commandes{
    border: 1px solid var(--grisprez);
  }
  .input-filtre-commandes::placeholder {
    color: var(--grisgadi);
    font-style: italic;
  }
  .commandes-toggle-btn {
    background-color: var(--grisgadi);
    font-size: 2.5rem;
    padding: 1rem;
    margin-left: 1rem;
  }
  .menu-smart.commandes{
    background-color: var(--blanc);
    box-shadow: -0.2rem 0px 1.2rem 0.6rem var(--noirshadow);
    height: 100vh;
    width: 100%;
    padding: 0;
  }
  .menu-header-appliquer i::before{
    color: var(--bleugadisat);
    justify-content: end;
    width: 100%;
  }
  .menu-title{
    align-items: center;
    font-family: var(--bold);
    padding-left: 1rem;
  }
  .menu-header-appliquer{
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    position: relative; /* Nécessaire pour le positionnement absolu du pseudo-élément */
  }
  .header-panel-filtre{
    border: none; /* Supprime toutes les bordures */
    box-shadow: 0px 0px 13.33px var(--bleutypofonce); 
    padding: 1rem 1rem;
  }
  .commandes-cocher{
    padding: 1rem 1rem;
  }
  .header-panel-filtre::after {
    content: '';
    background-color: var(--grisgadi);
    box-shadow: 0px 0px 16px var(--grisprez); 
  }
  .icon-title{
    align-items: center;
    padding-left: 0.8rem;
  }
  .text-menu-appliquer{
    align-items: center;
  }
  .appliquer{
    padding: 1rem;
    font-size: 1.3rem;
    font-family: var(--regular);
  }
  .text-menu-appliquer i::before{
    font-size: 2.197rem;
    color: var(--blanc);
  }
  .menu-toggle-btn-commande{
    background-color: var(--bleufond);
    color: var(--blanc);
    border-radius: 5px;
    width: 50%;
    height: 100%;
  }
  .panel-date-commandes{
    background-color: var(--grisclair);
    padding: 1rem;
    position: relative;
  }
  .panel-date-commandes::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; /* Prend toute la largeur de la fenêtre */
    height: 0.416rem;
    background-color: var(--bleufond); /* La couleur de la bordure */
  }
  .titre-date-commandes{
    text-transform: uppercase;
    color: var(--bleugadisat);
    font-size: 1.17rem;
    letter-spacing: 0.029rem;
  }
  .case-cocher{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 2rem 0;
  }
  .case1 { grid-area: 1 / 1 / 2 / 2; }
  .case2 { grid-area: 2 / 1 / 3 / 2; }
  .case3 { grid-area: 3 / 1 / 4 / 2; }
  .case4 { grid-area: 4 / 1 / 5 / 2; }
  .case5 { grid-area: 1 / 2 / 2 / 3; }
  .case6 { grid-area: 2 / 2 / 3 / 3; }
  .case7 { grid-area: 3 / 2 / 4 / 3; }
  .case8 { grid-area: 4 / 2 / 5 / 3; }

  /* Style de base pour les cases à cocher */
  .custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 1.3rem;
    padding-bottom: 2rem;
  }

  /* Masquer la case à cocher d'origine */
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Créer une case à cocher personnalisée */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--blanc);
    border: 1px solid var(--grisgadi);
  }

  /* Lorsque l'utilisateur clique sur la case à cocher, ajouter une bordure verte */
  .custom-checkbox input:checked + .checkmark {
    background-color: var(--bleufond);
  }

  /* Style de la coche (lorsque cochée) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Montrer la coche une fois cochée */
  .custom-checkbox input:checked + .checkmark:after {
    display: block;
  }

  /* Style de la coche */
  .custom-checkbox .checkmark:after {
    left: 5px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  .btn-reinitialiser{
    background-color: var(--bleufond);
    color: var(--blanc);
    border-radius: 5px;
    padding: 1rem;
    width: 80%;
    justify-content: center;
  }
  .texte-reinitialiser{
    font-family: var(--regular);
    font-size: 1.4rem;
    padding-left: 1rem;
  }
  .btn-reinitialiser i::before{
    color: var(--blanc);
    font-size: 1.65rem;
  }

  .titre-mes-commandes {
    align-items: center;
    color: var(--bleugadisat);
    display: flex;
    font-size: 2rem;
    font-family: var(--bold);
  }
  .list-items-mes-commandes{
    padding-bottom: 2rem;
  }
  .item-mes-commandes{
    padding: 1rem;
    margin-top: 2rem;
  }
  .panel-numero-commandes{
    background-color: var(--blanc);
    border-radius: 0 0 5px 5px;
  }
  .label-cde{
    font-size: 1.33rem;
  }
  .label-cde.mobile{
    font-size: 1.17rem;
    padding-top: 2rem;
  }
  .mobile-commandes{
    width: 100%;
  }
  .icon-commande .icon-retour{
    padding-right: 3rem;
  }
  .icon-commande{
    padding: 1.5rem 0 0 1.5rem;
  }
}