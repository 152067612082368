.panel-plusConsulter {
  justify-content: space-between;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.panel-plusConsulter article {
  padding: 1rem;
}

.panel-plusConsulter-famille {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.panel-plusConsulter-famille figure {
  width: 90%;
}
.panel-plusConsulter-famille ul {
  align-items: left;
  display: flex;
  flex-direction: column;
  font-size: clamp(1.6rem, 1.8rem, 2rem);
  margin-left: 15%;
  margin-top: 1rem;
}
.panel-plusConsulter li {
  padding-left: 0;
}
.panel-plusConsulter li::marker {
  display: inline-block;
  width: 0.8rem;
}
.panel-plusConsulter-famille ul li a {
  display: block;
  padding-bottom: 1rem;
  font-size: inherit;
}
.panel-plusConsulter-famille ul li::marker,
.panel-plusConsulter-famille ul li a {
  color: var(--bleutypofonce);
}
.panel-plusConsulter-famille ul li:last-child a {
  padding: 0;
}
.panel-plusConsulter-famille ul li.item-consulte-plus a,
.panel-plusConsulter-famille ul li.item-consulte-plus::marker {
  font-style: italic;
  color: var(--bleutypo);
}
.panel-plusConsulter-famille {
  background-color: var(--blanc);
  border: 0;
  border-radius: 0.5rem;
  display: block;
  color: var(--bleugadifonce);
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 899px) {
  .panel-plusConsulter {
    display: flex;
    justify-content: space-between;
    /* gap: 2rem; */
  }
  .panel-plusConsulter-famille {
    padding: 2rem;
    width: 18rem;
    box-shadow: 0 0 0.6rem var(--noirshadow);
  }

  .panel-plusConsulter li {
    font-size: 1.1669rem;
  }
  .btn-famille {
    border-radius: 0px;
    height: 3rem;
    margin: 0 -2rem;
    width: calc(100% + 4rem);
  }
  .panel-plusConsulter-famille figure {
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .panel-plusConsulter {
    gap: 3rem;
  }
}
@media screen and (min-width: 1200px) {
  .panel-plusConsulter {
    gap: 4rem;
    margin-bottom: 10.6rem;
  }
  .panel-plusConsulter-famille ul {
    margin-left: 30%;
  }
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
