.selection-nav-slider,
.container-select-nav,
.filter-selection-moment {
	display: flex;
}
/* .selection-nav-slider {
	flex-direction: column;
} */
.container-select-nav {
	justify-content: end;
}
.selection-nav-slider ul {
	list-style: none;
	margin-top: 2rem;
}
.filter-selection-moment li a {
	color: var(--grisgadi);
	padding: 2rem;
}
.filter-selection-moment li:last-child a {
	padding-right: 0;
}
.filter-selection-moment li a:hover,
.filter-selection-moment li a:visited {
	color: var(--grisgadi);
}
.filter-selection-moment li.actif a {
	color: var(--bleugadifonce);
}
.panel-selectionMoment {
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch;
}
.panel-article {
	background-color: var(--blanc);
	border: 0;
	border-radius: 0.5rem;
	box-shadow: 0 0 0.6rem var(--noirshadow);
	display: block;
	color: var(--bleugadifonce);
	padding: 5rem;
	padding-bottom: 4rem;
}
.panel-article:hover,
.panel-article:visited {
	color: var(--bleugadifonce);
}
.panel-article h3 {
	margin-top: 5rem;
}
.panel-article p {
	margin: 1.5rem 0 1rem 0;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 899px) {
	.selection-nav-slider i {
		margin: 0;
	}
	.panel-article {
		padding: 1rem;
		width: 18rem;
	}
	.panel-article h3 {
		padding: 1rem;
		margin-top: 2.055rem;
		font-size: 1.1669rem;
	}
	.panel-article p {
		margin: 0;
		padding: 0 0 1rem 1rem;
	}
	.panel-selectionMoment {
		display: flex;
		justify-content: space-between;
		gap: 2rem;
	}
	.euro-bleu {
		padding-left: 1rem;
		font-size: 2.9169rem;
		font-family: var(--bold);
	}
	.centimes-bleu {
		font-family: var(--bold);
	}
	.centimes-bleu,
	.prix .ht {
		font-size: 1.9169rem;
		top: -1rem;
	}
	.prix h4 {
		margin: 0;
	}
}
@media screen and (min-width: 992px) {
	.panel-article {
		padding: 2rem;
		padding-bottom: 0.5rem;
	}
}
@media screen and (min-width: 1200px) {
	.panel-article {
		padding: 5rem;
		padding-bottom: 4rem;
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
