.container-retours{
  padding: 5rem 0;
}
.panel-entete-mes-retours {
  background-color: var(--blanc);
  box-shadow: var(--shadow);
  padding: 6rem 4rem 6rem 4rem;
  margin-bottom: 5rem;
}
.panel-mes-retours {
  background-color: var(--blanc);
  box-shadow: var(--shadow);
  padding: 2rem 4rem 4rem 4rem;
  margin-bottom: 5rem;
}
.titre-btn{
  justify-content: space-between;
  width: 100%;
}
.titre-mes-retours {
  color: var(--bleugadisat);
  display: flex;
  font-size: 4rem;
}
.titre-mes-retours i {
  color: var(--bleugadisat);
  font-size: 5rem;
}
.titre-mes-retours span {
  display: block;
  height: 3rem;
  padding-left: 4rem;
}
.btn-un-retours{
  background-color: var(--bleugadisat);
  color: var(--blanc);
  border-radius: 5px;
  width: 29.3rem;
  font-family: var(--regular);
  /* margin-right: 4rem; */
}
p.texte-entete-retours{
  color: var(--bleugadisat);
}
.texte-entete-retours span{
  display: block;
}

/* .panel-mes-retours{
  background-color: var(--blanc);
  box-shadow: var(--shadow);
  padding: 6rem 4rem 6rem 4rem;
} */
.panel-mes-retours-recherche{
  background-color: var(--blanc);
  box-shadow: var(--shadow);
  padding: 2rem 4rem 4rem 4rem;
}
h3.recherche-retour-text {
  font-size: 2.7rem;
  padding-bottom: 3rem;
}
p.recherche-retour-text {
  font-size: 2.1rem;
  padding-bottom: 1rem;
}
input.numero-retour {
  border: 1px solid var(--grisprez);
  flex-grow: 1;
  margin-bottom: 3rem;
  width: 100%;
}
.input-group-retour {
  display: flex;
  align-items: center;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
  margin: 0;
  margin-bottom: 1rem;
}
.input-group-retour span {
  padding: 1.6rem 2rem;
  background-color: var(--grisclair);
  border: 1px solid var(--grisprez);
  border-radius: 5px 0 0 5px;
  border-right: none;
}
.input-group-retour input.periode-achat {
  flex: 1;
  border: none;
  padding: 0 2rem;
  border: 1px solid var(--grisprez);
  border-radius: 0 5px 5px 0;
}
.input-group-retour input.periode-achat.libelle-bleu {
  color: var(--bleugadisat);
}
.rechercher-retour-btn {
  align-items: center;
  background-color: var(--bleugadisat);
  border: none;
  border-radius: 5px;
  color: var(--blanc);
  cursor: pointer;
  display: flex;
  height: 5.5rem;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
}
input.numero-retour::placeholder{
  color: var(--grisgadi);
  font-style: italic;
}
.input-group-retour input.periode-achat::placeholder{
  color: var(--grisgadi);
  font-style: italic;
  font-size: 1.5rem;
}

.titre-retours-en-cours {
  align-items: center;
  color: var(--bleugadisat);
  display: flex;
  font-size: 3rem;
  font-family: var(--bold);
}
.titre-retours-en-cours i {
  color: var(--bleugadisat);
  font-size: 5rem;
  margin-right: 2rem;
}
.item-mes-retours {
  box-shadow: var(--shadow);
  margin-top: 4rem;
  padding: 4rem;
  border-radius: 5px 5px 0 0;
  background-color: var(--grisclair);
  padding: 1.5rem 3rem 1.5rem 3rem;
}
.label-retours-en-cours {
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.label-retours {
  color: var(--bleugadisat);
  font-size: 2rem;
}

.combien-retour-passees {
  align-items: end;
}
p.retour-passees {
  font-family: var(--bold);
}
.retour-passees {
  color: var(--bleugadisat);
  margin-top: 4rem;
}
.dropdown-select-retour {
  cursor: pointer;
  height: 3.9rem;
  border: .1rem solid var(--grisgadi);
  background-color: var(--grisclair);
  border-radius: .5rem;
  color: var(--bleugadisat);
  font-size: 1.5rem;
  font-family: var(--bold);
  align-items: center;
  display: flex;
  padding: 0 2rem;
}

.dropdown-checkbox-container {
  display: flex;
  align-items: center;
}

.dropdown {
  margin-right: 20px; /* Ajustez l'espace entre le dropdown et les cases à cocher */
}

.checkboxes {
  display: flex;
  gap: 4rem;
}

.checkboxes label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.checkboxes input[type="checkbox"] {
  width: 2.8rem; /* Largeur de la case à cocher */
  height: 2.8rem; /* Hauteur de la case à cocher */
  background-color: var(--blanc);; /* Fond blanc */
  border: 2px solid var(--grisprez); /* Bordure grise */
  appearance: none; /* Supprime le style par défaut de la case à cocher */
  cursor: pointer;
  position: relative;
  border-radius: 3px;
}

.checkboxes input[type="checkbox"]:checked {
  background-color: var(--blanc); /* Fond blanc quand la case est cochée */
  border: 2px solid var(--bleugadisat); /* Bordure bleu foncé */
}

.checkboxes input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 0;
  left: 8px;
  width: 1rem;
  height: 1.5rem;
  border: solid var(--bleugadisat); /* Couleur de la coche bleu foncé */
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkboxes label span {
  margin-left: 1rem;
  font-size: 2rem;
}

@media screen and (max-width: 1024px){
  .titre-mes-retours {
    font-size: 2rem;
    padding: 2rem 0rem 1rem 0rem;
  }
  .titre-btn{
    flex-direction: column;
  }
  p.texte-entete-retours{
    font-size: 1.17rem;
    font-style: italic;
  }
  .titre-mes-retours i{
    font-size: 2.63rem;
  }
  .titre-retours-en-cours i{
    font-size: 3.28rem;
  }
  .titre-mes-retours span{
    padding-left: 1rem;
    font-family: var(--bold);
  }
  .container-retours {
    padding: 0 2rem;
  }
  .panel-mes-retours{
    background-color: transparent;
    box-shadow: none;
    padding: 2rem 0rem;
    margin: 0;
  }
  .recherche-filtre-retour {
    padding: 2rem 0;
  }
  .input-filtre-retour {
    border: 1px solid var(--grisprez);
  }
  .menu-smart.retour {
    background-color: var(--blanc);
    box-shadow: -0.2rem 0px 1.2rem 0.6rem var(--noirshadow);
    height: 100vh;
    width: 100%;
    padding: 0;
  }
  .header-panel-filtre {
    border: none;
    box-shadow: 0px 0px 13.33px var(--bleutypofonce);
    padding: 1rem 1rem;
  }
  .menu-header-appliquer {
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    position: relative;
  }
  .icon-title {
    align-items: center;
    padding-left: 0.8rem;
  }
  .menu-toggle-btn-retour {
    background-color: var(--bleufond);
    color: var(--blanc);
    border-radius: 5px;
    width: 50%;
    height: 100%;
  }
  .list-items-mes-retours {
    padding-bottom: 2rem;
  }
  .item-mes-retours {
    padding: 1rem;
    margin-top: 2rem;
  }
  .mobile-retours {
    width: 100%;
  }
  .label-retours {
    font-size: 1.33rem;
  }
  .label-retours.mobile {
    font-size: 1.17rem;
    padding-top: 2rem;
  }
  .recherche-filtre-retour {
    padding: 2rem 0;
    width: 100%;
  }

}