.erreur-page{
  justify-content: center;
  padding: 10rem 0;
}
.figure-erreur{
  width: 55.4rem;
  height: 69.2rem;
}
.premier-mot {
  text-transform: uppercase;
  font-size: 14rem;
}
/* .texte-ligne-suivante {
  display: block; 
  text-transform: lowercase;
} */
.desole-pageNonTrouvee{
  color: var(--bleugadisat);
  font-family: var(--thin);
  font-size: 7rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-left: 7rem;
}

.recherche-pageAccueil{
  color: var(--bleugadisat);
  font-family: var(--thin);
  font-size: 3rem;
}

.recherche-pageAccueil2{
  display: block;
  font-size: 3rem;
}

.page-accueil-lien {
  font-size: 3rem; /* Augmente la taille de la police */
  color: var(--bleufond); /* Couleur du texte du lien (bleu dans cet exemple) */
  text-decoration: underline; /* Souligne le texte */
}

.page-accueil-lien:hover {
  color: var(--grisgadi); /* Souligne le texte au survol */
}

@media screen and (max-width: 1024px) {
  .premier-mot{
    font-size: 6rem;
  }
  .erreur-page{
    flex-direction: column;
    align-items: center;
    padding: 4rem;
  }
  .figure-erreur{
    width: 13.33rem;
    height: 16.66rem;
  }
  .desole-pageNonTrouvee{
    padding-left: 0;
    padding-top: 2rem;
  }
  .texte-ligne-suivante{
    font-size: 2.33rem;
  }
  .recherche-page-accueil{
    font-size: 5rem;
  }
  .recherche-pageAccueil,
  .recherche-pageAccueil2,
  .page-accueil-lien{
    font-size: 1.33rem;
  }
}