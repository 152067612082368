/**********/
/* GESTION DES TITRES */
/**********/
h1 {
	color: var(--blanc);
	font-size: 4rem;
	font-weight: 600;
	line-height: 1.2;
}
h2 {
	font-size: 2rem;
}
h3 {
	font-size: 1.8rem;
}
h4 {
	font-size: 1.4rem;
}
p,
a {
	font-size: 1.6rem;
	line-height: 1.25;
}
button {
	font-size: 1.6rem;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
	h1 {
		font-size: 4rem;
		line-height: 1.3;
	}
	h2 {
		font-size: 2rem;
	}
	h3 {
		font-size: 2.4rem;
	}
	h4 {
		font-size: 1.6rem;
	}
	p,
	a {
		font-size: 1.6rem;
	}
}
@media screen and (min-width: 1800px) {
	p,
	a {
		font-size: 1.8rem;
	}
	button {
		font-size: 1.8rem;
	}
}
/****************
* GESTION FONT ICONS
****************/
@font-face {
	font-family: "autoref";
	src: url("../fonts/autoref.ttf?swosx4") format("truetype"),
		url("../fonts/autoref.woff?swosx4") format("woff"),
		url("../fonts/autoref.svg?swosx4#autoref") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
/***************
* GESTION FONT NEW FRANK
***************/
@font-face {
	font-family: "new_frankbold";
	src: url("../fonts/fonnts.com-new_frank_bold-webfont.woff2") format("woff2"),
		url("../fonts/fonnts.com-new_frank_bold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "new_nordmedium";
	src: url("../fonts/fonnts.com-new_nord_medium-webfont.woff2") format("woff2"),
		url("../fonts/fonnts.com-new_nord_medium-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "new_frankregular";
	src: url("../fonts/fonnts.com-new_frank_regular-webfont.woff2")
			format("woff2"),
		url("../fonts/fonnts.com-new_frank_regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "new_franklight";
	src: url("../fonts/fonnts.com-new_frank_light-webfont.woff2") format("woff2"),
		url("../fonts/fonnts.com-new_frank_light-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "new_frankthin";
	src: url("../fonts/fonnts.com-new_frank_thin-webfont.woff2") format("woff2"),
		url("../fonts/fonnts.com-new_frank_thin-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

:root {
	--bold: "new_frankbold";
	--medium: "new_nordmedium";
	--regular: "new_frankregular";
	--light: "new_franklight";
	--thin: "new_frankthin";
}
