.contour-blanc-mon-compte{
	width: 168rem;
	height: auto;
  margin: auto;
	margin-top: 6rem;
	margin-bottom: 6rem;
	background-color: var(--blanc);
  border-radius: 5px;
  padding: 4rem;
}

.mon-compte-icon{
	color: var(--bleugadisat);
	font-size: 4rem;
	display: flex;
  padding-right: 4rem;
}

.texte-mon-compte{
  font-size: 3rem;
  color: var(--bleugadisat);
  font-family: var(--bold);
}

.icon-mon-compte{
  margin-bottom: 4rem;
}

.panel-monCompte{
  border-radius: 0px;
  width: 160rem;
  /* height: 6.7rem; */
  background-color: var(--grisclair);
}
.mes-infos, .mes-adresses, .mes-parametres{
  padding: 2.8rem;
  color: var(--bleugadisat);
  font-size: clamp(1.5rem, 1.6rem, 1.7rem);
  font-family: var(--regular);
}

.btn-deconnexion {
  /* Ajoutez ici votre style pour le bouton */
  background-color: var(--bleufond);
  font-size: clamp(1.5rem, 1.6rem, 1.7rem);
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* margin-left: 122.3rem;
  margin-top: 4rem; */
}
.btn-deconnexion:hover {
  background-color: var(--bleugadisat);
}

.mon-compte-info{
  font-size: 8rem;
  color: var(--grisgadi);
  padding: 4.49rem 9.83rem;
}

.mon-compte-livraison{
  font-size: 8rem;
  color: var(--grisgadi);
  padding: 4.49rem 9.83rem 4.49rem 0.83rem;
}

.info-compte,
.info-garage{
  color: var(--bleugadisat);
  font-family: var(--light);
  text-transform: uppercase;
  gap: 12rem;
  padding-top: 4rem;
}

.info-compte h4{
  font-size: clamp(1.6rem, 1.7rem, 1.8rem);
  line-height: 1.2; 
}
.info-garage h4{
  font-size: clamp(1.6rem, 1.7rem, 1.8rem);
  line-height: 1.2; 
}

.panel-mes-parametres{
  width: 159.8rem;
  border-radius: 5px;
  border: 1px solid var(--grisprez);
  margin-left: 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mon-compte-prix{
  font-size: 12rem;
  color: var(--grisgadi);
  margin-left: 6rem;
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.panel-priorite-prix, .panel-prix-remise {
  width: 130.1rem;
  height: 2rem;
  border-radius: 0px;
  border-bottom: 1px solid var(--grisprez); /* Appliquer une bordure gauche */
  border-right: none;
  border-top: none;
  border-left: none;
  margin-left: 8rem;
  margin-top: 4rem;
  padding-bottom: 4rem;
  font-family: var(--light);
}

.panel-taux-remise{
  width: 130.1rem;
  height: 2rem;
  border-radius: 0px;
  border-bottom: none;
  border-right: none;
  border-top: none;
  border-left: none;
  margin-left: 8rem;
  margin-top: 3rem;
  font-family: var(--light);
}

.mon-compte-gammes{
  font-size: 10rem;
  color: var(--grisgadi);
  margin-left: 6rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
}

.panel-mes-gammes{
  width: 159.8rem;
  height: 17.7rem;
  border-radius: 5px;
  border: 1px solid var(--grisprez);
  margin-left: 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.panel-gammes-resultats {
  width: 130.1rem;
  height: 2rem;
  border-radius: 0px;
  border-bottom: 1px solid var(--grisprez); /* Appliquer une bordure gauche */
  border-right: none;
  border-top: none;
  border-left: none;
  margin-left: 9.2rem;
  margin-top: 4rem;
  padding-bottom: 4.5rem;
  font-family: var(--light);
}

.panel-gammes-vehicules {
  width: 130.1rem;
  height: 2rem;
  border-radius: 0px;
  border-bottom: none;
  border-right: none;
  border-top: none;
  border-left: none;
  margin-left: 9.2rem;
  margin-top: 3.7rem;
  padding-bottom: 4rem;
  font-family: var(--light);
}

.switch {
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 3.1rem;
  margin-left: -25.5rem;
  margin-top: 3rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grisgadi);
  transition: .4s;
  border-radius: 3.4rem; /* Rounded corners */
}

.slider:before {
  position: absolute;
  content: "";
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--bleufond);;
}

input:checked + .slider:before {
  transform: translateX(2.6rem);
}

.gris-gadi{
  color: var(--grisgadi);
  font-size: clamp(1.3rem, 1.4rem, 1.5rem);
  width: 80px;
  height: 24px;
  margin-left: -25.5rem;
  margin-top: 3rem;
}

.modifier-bouton-info {
  margin-top: 20px; /* Espace au-dessus du bouton */
  text-align: center; /* Centrer le bouton */
}

.modifier-bouton-info button {
  display: flex;
  align-items: center;
  padding: 10px 20px; /* Espacement interne du bouton */
  font-size: 16px; /* Taille de la police du bouton */
  border: none; /* Enlever les bordures par défaut */
  background-color: var(--grisclair); /* Couleur de fond du bouton */
  color: var(--grisgadi); /* Couleur du texte du bouton */
  border-radius: 5px; /* Coins arrondis du bouton */
  cursor: pointer; /* Curseur en forme de main au survol */
  width: 217px;
  height: 39px;
  margin-left: 40.5rem;
  margin-top: 3rem;
  margin-right: 4rem;
  margin-bottom: 4rem;
  padding-right: 7rem;
  border: 1px solid var(--grisgadi);
}

.modifier-bouton-info button:hover {
  background-color: var(--grisgadi); /* Couleur de fond du bouton au survol */
  color: var(--grisclair);
}

.crayon-modif{
  margin-right: 4rem;
}

.modifier-bouton-livraison{
  margin-top: 20px; /* Espace au-dessus du bouton */
  text-align: center; /* Centrer le bouton */
}

.modifier-bouton-livraison button {
  display: flex;
  align-items: center;
  padding: 10px 20px; /* Espacement interne du bouton */
  font-size: 16px; /* Taille de la police du bouton */
  border: none; /* Enlever les bordures par défaut */
  background-color: var(--grisclair); /* Couleur de fond du bouton */
  color: var(--grisgadi); /* Couleur du texte du bouton */
  border-radius: 5px; /* Coins arrondis du bouton */
  cursor: pointer; /* Curseur en forme de main au survol */
  width: 217px;
  height: 39px;
  margin-left: 39.4rem;
  margin-top: 14rem;
  margin-right: 4rem;
  margin-bottom: 4.5rem;
  padding-right: 7rem;
  border: 1px solid var(--grisgadi);
}

.modifier-bouton-livraison button:hover {
  background-color: var(--grisgadi); /* Couleur de fond du bouton au survol */
  color: var(--grisclair);
  display: flex;
  align-items: center; /* Pour centrer verticalement le bouton */
}

input.adresse {
  margin-bottom: 5px;    /* Espace entre le label et l'input */
  font-size: clamp(1.3rem, 1.4rem, 1.5rem);
  height: 2rem; 
  border: 1px solid var(--grisgadi);
  font-style: italic;
}
.adresse{
  white-space: nowrap; /* Empêche le texte de se plier sur plusieurs lignes */
  overflow: hidden;    /* Cache le texte qui dépasse le conteneur */
  text-overflow: ellipsis; /* Ajoute des points de suspension à la fin du texte si nécessaire */
}

.garage-adresse{
  gap: 4rem;
  display: grid;
  margin-right: 20px;
}

.garage-adresse2{
  gap: 4rem;
  display: grid;
}

.garage-info1{
  margin-left: 1rem;
}

.garage-info2{
  margin-left: 18.5rem;
}

.selection-priotite-prix, .selection-gammes-resultat, .selection-gammes-vehicules{ 
  width: 217px;
  height: 39px;
  border: 1px solid var(--grisgadi);
  background-color: var(--grisclair);
  margin-left: -25.5rem;
  border-radius: 5px;
  margin-top: 2rem;
}
/* .selection-priotite-prix:focus{
  border-color: var(--bleutypo); 
  border: 1px solid var(--bleutypo);
  border-radius: 5px;
} */


.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-select {
  padding: 0 10px;
  cursor: pointer;
  width: 21.7rem;
  height: 3.8rem;
  border: .1rem solid var(--grisgadi);
  background-color: var(--grisclair);
  margin-left: -25.5rem;
  border-radius: .5rem;
  margin-top: 2rem;
  color: var(--bleugadisat); /* Couleur de texte par défaut */
  font-size: clamp(1.3rem, 1.4rem, 1.5rem);
  font-family: var(--bold);
  /* text-indent: 10px; */
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.dropdown-select.selected {
  border-color: var(--bleufond); /* Contour bleu lors de la sélection */
  color: var(--bleufond); /* Texte bleu lors de la sélection */
}
.dropdown-menu-generique {
  display: none;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0px 0px 6px #00000029;
  text-indent: 10px;
}
.dropdown-menu-generique.show {
  display: block;
  margin-left: -25.5rem;
  width: 217px;
}
.dropdown-menu-generique:focus{
  border-color: var(--bleutypo);
}
.dropdown-item {
  padding: 10px;
  cursor: pointer;
  font-size: clamp(1.3rem, 1.4rem, 1.5rem);
  color: var(--bleugadisat);
}
.dropdown-item:hover {
  color: var(--bleufond);
}
/* 
.fleche-bas{
  margin-left: 7rem;
} */

.fleche-bas::before {
  transition: transform 0.3s ease, color 0.3s ease; /* Pour une transition fluide */ 
}

.fleche-bas.up::before {
  transform: rotate(180deg); /* Rotation pour faire pointer la flèche vers le haut */
}

.compte-deco{
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 576px){
  .info,
  .info-compte,
  .adresse-livraison,
  .info-garage {
  flex-direction: column;
  }
  .info-compte,
  .info-garage{
    gap: 1rem;
    padding: 0 0 4rem 1rem;
  }
}
@media screen and (max-width: 1024px){
  .contour-blanc-mon-compte{
    margin: 0;
  }
  .icon-mon-compte{
    align-items: center;
    margin-bottom: 2rem;
  }
  .texte-mon-compte{
    margin: 0;
    font-size: 2rem;
    padding: 0 1rem;
  }
  .panel-monCompte{
    margin: 0;
    width: 100%; 
  }
  .mon-compte-icon{
    margin: 0;
    font-size: 2.73rem;
    padding-right: 0;
  }
  .btn-deconnexion{
    margin: 0;
    font-size: 1rem;
  }
  .contour-blanc-mon-compte{
    width: 100%;
    padding: 2rem;
  }
  .mes-infos,
  .mes-adresses{
    padding: 1rem;
  }
  .mon-compte-info,
  .mon-compte-livraison{
    padding: 2rem 0 1rem 1rem;
    font-size: 3.18rem;
  }
}
