footer {
	background-color: var(--bleugadifonce);
}
.faq {
	background-color: var(--bleugadisat);
	padding: 10rem 0;
}
.faq h2 {
	font-size: clamp(3rem, 6rem, 6rem);
	color: var(--blanc);
	text-transform: uppercase;
}
.row-faq,
.container-textuel-faq,
.info-faq {
	display: flex;
	flex-direction: column;
}
.container-textuel-faq {
	flex-direction: column-reverse;
}

.rowInfoCompteContact {
	padding: 5rem 0;
}
.infoSection p {
	display: flex;
	flex-direction: column; /* Aligne les éléments verticalement */
	color: var(--bleufond);
	text-transform: uppercase;
	font-size: 20px;
	font-weight: bold;
	padding-bottom: 2rem;
	margin: 0 auto;
}
.infoSection ul {
	color: var(--grisgadi);
	font-size: clamp(1.6rem, 1.8rem, 2rem);
	padding-left: 2rem;
}
.infoSection li:hover {
	color: var(--blanc);
}
.infoSection ul li {
	padding-bottom: 1rem;
}
.infoSection ul li a {
	color: inherit;
	cursor: pointer;
	display: block;
	font-size: inherit;
}
.infoComplementaire::after {
	content: "";
	display: block;
	width: 100%;
	height: 1rem;
	background-color: var(--bleugadisat);
}
.tecdoc p{
	color: var(--bleugris);
	font-size: 1.8rem;
	padding: 6rem 0rem;

}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 899px) {
	.faq{
		padding: 5rem 0 0 0;
	}
	.faq h2{
		font-size: 2.6669rem;
		padding: 3rem 0;
		letter-spacing: 0.4rem;
	}
	.tecdoc{
		flex-direction: column;
		align-items: center;
	}	
	.tecdoc p{
		font-size: 0.8331rem;
		padding: 6rem 2rem;
    margin-top: -5rem;
	}
	.img-tecdoc{
		width: 17.2494rem;
    height: 5.22rem;
		margin: 2rem 0 0 0;
	}
	.infoSection p {
		font-size: 1.5rem;
	}
	.infoSection ul{
		font-size: 1.1669rem;
		list-style: none;
		padding: 0;
		line-height: 3;
	}
	.infoSection ul li{
		padding-bottom: 2.2rem;
	}
	.rowInfoCompteContact{
		display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
		padding: 3rem 0;
	}
	.infoS1{
		grid-area: 1 / 1 / 2 / 2;
		margin-top: 2rem;
	}
	.infoS2{
		grid-area: 1 / 2 / 2 / 3;
		margin-top: 2rem;
	}
	.infoS3{
		grid-area: 2 / 1 / 3 / 2;
		margin-top: 2rem;
	}
	.infoS4{
		grid-area: 2 / 2 / 3 / 3;
		margin-top: 2rem;
	}
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
	.row-faq,
	.container-textuel-faq {
		flex-direction: row;
		justify-content: center;
	}
	.container-textuel-faq {
		/* margin-right: 4rem;à réactiver avec les FAQ */
		width: 60%;
	}
	.container-textuel-faq .col-50 {
		margin-right: 12rem;
		width: 50%;
	}
	.container-video-faq {
		margin-left: 4rem;
	}
	.video-faq,
	.info-faq {
		height: 37.5rem;
	}
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
