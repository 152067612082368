header {
	background-color: var(--bleugadisat);
}
header.nav-after {
	padding-top: 2rem;
}
.top-header {
	background-color: var(--bleufond);
	height: 2.5rem;
}
.top-header .container {
	align-items: center;
	display: flex;
	height: inherit;
	justify-content: space-between;
}
.top-header h1,
.top-header p {
	color: var(--blanc);
	font-size: 1.33311rem;
	line-height: 0.85;
	font-family: var(--light);
}
.top-header img {
	height: auto;
	width: 1.75rem;
}
.header-header {
	background-color: var(--bleugadisat);
	position: relative;
	z-index: 1;
}
.header-nos-marques {
	display: flex;
	align-items: center;
}
.header-panel {
	position: relative;
}
.header-panel .input-group {
	position: relative;
	display: inline-flex;
}
.header-plus-consulter {
	display: flex;
	align-items: center;
}
.header-selection-moment {
	display: flex;
	align-items: center;
}
.header-toutes-famille {
	display: flex;
	align-items: center;
}
.header-sites-partenaires {
	display: flex;
	align-items: center;
}
.input-group {
	margin: 1rem;
	/* width: 35%; */
	display: flex;
	align-items: center;
	border-radius: 4px 0 0 4px;
	overflow: hidden;
}
.header-panel .input-group {
	margin: 0;
	margin-top: 1.5rem;
	position: relative;
	display: inline-flex;
}

.mon-panier {
	background-color: var(--bleugadisat);
	color: var(--blanc);
	position: absolute;
	font-size: 5rem;
	display: flex;
}

.search-panier-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 899px) {
	/* .mon-panier{
		background-color: transparent;
    color: var(--bleufond);
		font-size: 3rem;
		margin: 0;
	} */
	.top-header .container {
		padding: 0 2rem;
	}
}
@media screen and (min-width: 992px) {
	.header-header {
		padding-top: 2rem;
	}
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
