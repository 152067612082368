.encart-promo figure {
	height: inherit;
	width: 100%;
}
.carrousel {
	height: 528px;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.carrousel-interieur {
	display: flex;
	transition: transform 0.5s ease-in-out;
}
.carrousel-element {
	display: none;
	min-width: 100%;
	transition: opacity 1s;
	opacity: 0;
	height: inherit;
}
.carrousel-element.actif {
	opacity: 1;
	display: block;
}
.points {
	text-align: center;
	position: absolute;
	bottom: 2rem;
	width: 100%;
}
.point {
	display: inline-block;
	width: 1.7rem;
	height: 1.7rem;
	background-color: var(--blanc);
	border-radius: 100%;
	margin: 0 5px;
	cursor: pointer;
	opacity: 0.6;
}
.point.actif,
.point:hover {
	background-color: var(--blanc);
	opacity: 1;
}
@media screen and (max-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 899px) {
	.carrousel-element figure {
		border-radius: 0px;
		margin-top: 1rem;
	}
	.point {
		width: 1rem;
		height: 1rem;
	}
	.encart-promo figure {
		border-radius: 0px;
		margin-top: 1rem;
	}
}
@media screen and (min-width: 992px) {
	.carrousel {
		height: 528px;
	}
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
