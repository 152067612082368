.info-proprietaire i::before {
	align-items: center;
	border-radius: 0.5rem;
	margin-left: 4.5rem;
	display: flex;
	justify-content: center;
	cursor: pointer;
	color: var(--bleufond);
	background-color: var(--blanc);
	width: 5rem;
	height: 5rem;
}
.nom-propietaire {
	padding: 0 1rem;
	color: var(--bleugadisat);
}
.panel-client-info {
	position: relative;
	z-index: 11;
}

.info-vehic-client {
	align-items: center;
	color: var(--bleufond);
}

.icon-client::before {
	font-size: 2.5rem;
	height: 2.5rem;
	width: 1.1rem;
	justify-content: center;
	align-items: center;
	display: flex;
}
h2.client-existant,
h2.nouveau-client {
	border-bottom: 2px solid var(--grisclair);
	color: var(--bleufond);
	text-transform: uppercase;
	justify-content: space-between;
}

.modal-content-client {
	background-color: #fff;
	padding: 4rem 0;
	margin: 0 auto;
	border: 2px solid #f5f5f5;
	border-top: none;
	border-radius: 0 0 5px 5px;
}

.input-group-ref-client,
.info-client-nouveau {
	width: 50%;
}
.input-recherche-client {
	margin-top: 2rem;
}
input.nom-client-existant {
	border: 1px solid var(--grisprez);
	flex-grow: 1; /* Utilise tout l'espace disponible */
	width: 61.5rem;
}

.input-group-client {
	margin-top: 2rem;
	display: flex;
	align-items: center;
	border-radius: 4px;
}
.input-group-client i {
	height: 100%;
	width: 5rem;
}
.input-group-client i::before {
	font-size: 3rem;
	height: 5rem;
	width: 100%;
	background-color: var(--bleugadisat);
	color: var(--blanc);
	border-radius: 5px 0 0 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}
i.icon.icon-email::before {
	font-size: 2rem;
}
input.nom-client-nouveau:first-child {
	border: 1px solid var(--grisprez);
	flex-grow: 1; /* Utilise tout l'espace disponible */
	width: 63rem;
}

input.nom-client-nouveau {
	border: 1px solid var(--bleugadisat);
	border-left: none;
	flex-grow: 1; /* Utilise tout l'espace disponible */
	width: 63rem;
	border-radius: 0 5px 5px 0;
}
.cp-ville {
	display: flex;
	width: 100%; /* Utilise toute la largeur disponible */
}
.cp-ville i {
	display: block;
}
.cp-ville i:last-of-type {
	margin-left: 1rem;
}

.cp {
	flex: 1; /* Le code postal prendra 1 part de l'espace */
	border: 1px solid var(--bleugadisat);
	border-radius: 0 5px 5px 0;
}

.ville {
	flex: 3; /* La ville prendra 3 parts de l'espace disponible */
	border: 1px solid var(--bleugadisat);
	border-radius: 0 5px 5px 0;
}

input.nom-client-existant::placeholder,
.nom-client-nouveau::placeholder,
.cp::placeholder,
.ville::placeholder {
	color: var(--grisgadi);
	font-style: italic;
}

.tab-infoCli {
	display: flex;
	gap: 2.5rem;
	margin: 0 auto;
	width: 90%;
}
.nom-client-nouveau {
	margin-right: 0;
}

.search-button-client {
	align-items: center;
	border: none;
	border-radius: 5px;
	display: flex;
	font-size: clamp(1.6rem, 1.8rem, 2rem);
	justify-content: center;
	height: 6rem;
	width: 26rem;
	margin-top: 4rem;
}
.ajout-button-client {
	background-color: var(--bleufond);
	color: var(--blanc);
	font-family: var(--regular);
}
.supprimer-client-existant {
	margin-top: 4rem;
}

.supprimer-button-client {
	align-items: center;
	border: none;
	border-radius: 5px;
	display: flex;
	font-size: clamp(1.6rem, 1.8rem, 2rem);
	justify-content: center;
	/* height: 5rem;
  width: 4rem; */
	margin-top: 4rem;
	margin: 0 auto;
	background-color: var(--rougepl);
	color: var(--blanc);
	font-family: var(--regular);
}
.suppression-client {
	padding-left: 1rem;
}
.separation-proprio {
	border-bottom: 1px solid var(--grisgadi);
	margin-top: 1rem;
	padding-bottom: 2rem;
}

.tab-info-cli-nouveau li:nth-child(2n + 1) {
	background-color: var(--grisclair);
}
.tab-info-cli-nouveau li {
	height: 3.5rem;
	display: flex;
	align-items: center;
	font-size: clamp(1.5rem, 1.5rem, 1.6rem);
}
.info-cli-nouveau {
	margin-top: 2rem;
}
.info-cli {
	padding: 0rem 2rem;
	display: flex;
	width: 100%;
}
.info-cli p {
	color: var(--bleugadisat);
	font-size: 1.4rem;
	width: 65%;
}
.info-cli p:first-child {
	color: var(--grisgadi);
	font-size: 1.5rem;
	width: 35%;
}
.info li {
	color: var(--grisgadi);
	padding-left: 2rem;
}
.info-rep li {
	padding-left: 4rem;
	padding-right: 4rem;
}
.rep-info-cli {
	color: var(--bleugadisat);
	font-size: 2.1rem;
}

.rep-info-cli.nom {
	font-family: var(--bold);
}

.ajouter-client {
	font-family: var(--regular);
	font-size: 1.6rem;
}

p.nom {
	font-family: var(--bold);
}
@media screen and (max-width: 576px) {
	.tab-infoCli {
		flex-direction: column;
		width: 100%;
	}
	.input-group-ref-client,
	.info-client-nouveau {
		width: 100%;
	}
	.info-cli p:first-child,
	.info-cli p {
		font-size: 1.2rem;
		padding: 0;
	}
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1800px) {
}
@media screen and (max-width: 899px) {
	.icon-client::before {
		font-size: 2rem;
	}
	.info-proprietaire i::before {
		width: 3rem;
		height: 3rem;
		margin-left: 3rem;
	}
	.nom-propietaire p {
		font-size: 1.3rem;
	}
	.nom-propietaire h2 {
		font-size: 0.8rem;
	}
	.close-client {
		margin-left: 0;
	}
	.modal-content-client {
		padding: 2rem;
	}
	h2.client-existant,
	h2.nouveau-client {
		width: 100%;
	}
}

@media screen and (max-width: 1024px) {
	.input-group-client i {
		width: 15rem;
	}
	.supprimer-client-existant {
		margin-top: 2rem;
	}
	.supprimer-button-client {
		font-size: 1.2rem;
		height: 6rem;
		width: 26rem;
	}
	.search-button-client {
		margin-top: 2rem;
	}
	.ajouter-client {
		font-size: 1.2rem;
	}
	.info-cli p {
		padding-left: 1rem;
	}
	.cp {
		width: 9.5rem;
	}
	.cp-ville .icon {
		width: 3rem;
	}

	.cp-ville .input-group-client {
		align-items: center; /* Assure que l'icône et l'input sont bien alignés */
	}
	.search-button.search-header-client {
		border-radius: 0 5px 5px 0;
	}
}

@media screen and (min-width: 1200px) {
	.search-button.search-header-client {
		width: 6rem;
		border-radius: 0 0.5rem 0.5rem 0;
		height: 5rem;
	}
}
